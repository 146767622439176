<template>
	<div class="bg-f2f align-center">
        <v-container class="h-100">
           <v-card color="transparent" max-width="1400" rounded="0" elevation="0" class="mx-auto pt-7">
                 <v-row no-gutters  justify="center">
                    <v-col cols="12" class="pb-16">
                       <v-row no-gutters>
                             <v-col cols="12" lg="5" offset="1" class="justify-center r-m-l-0">
                                <!-- <h2 class="body-h5 text-primaryGrey ml-13">unNFT</h2> -->
                                <!-- 返回按钮 -->
                                <v-icon size="28" aria-label="chevron-left-circle" icon="mdi:mdi-chevron-left-circle text-primaryGrey gradient-text-hover mb-3 ml-13 r-m-l-0" @click="$router.back(-1)"></v-icon>
                                <h1 class="sub-page-h1 ml-13 r-m-l-0 text-primaryGrey">Buy Now</h1>
                                <!-- --------------------- BUY ------------------------ -->
                                <div class="mt-7" v-if="action == 'BUY'">
                                    <v-card max-width="560" class="ml-13 r-m-l-0 pa-8 mb-16 mt-8" elevation="2" :rounded="0">
                                        <h5 class="body-p text-primaryGrey">Once the transaction is confirmed, the NFT will be sent to your wallet instantly.</h5>
                                        <v-row no-gutters class="justify-space-between mt-16">
                                            <h2 class="body-h2 text-primaryGrey">{{ nftListInfo.isListed ? $tools.formatNumber(nftListInfo.price) : '--' }}</h2>
                                            <h2 class="body-h2 text-primaryGrey">{{ nft.paymentToken }}</h2>
                                        </v-row>
                                        <v-row no-gutters class="justify-space-between mt-7">
                                            <h5 class="body-p text-primaryGrey">{{ '(~$' + $tools.formatNumber(totalPrice, 2) + ')'}}</h5>
                                        </v-row>
                                        <v-row no-gutters class="justify-space-between mt-7">
                                            <h5 class="body-p text-primaryGrey">Available Balance</h5>
                                            <h3 class="body-text-btn text-primaryGrey">{{ $tools.formatNumber(balance, 4) }} {{ nft.paymentToken }}</h3>
                                        </v-row>
                                    </v-card>
                                    <!-- 没有购买权限则禁用 -->
                                    <div class="mt-16 ml-13 r-m-l-0 gradient-left-red-purple-300">
                                        <v-btn v-if="!isInsufficientFunds" rounded="0" elevation="2" :disabled="!hasBuyPermission" :loading="buyLoading" color="button01" class="text-none text-grey05 body-p-small-b" width="300" height="66" @click="buy()" aria-label="Confirm">Confirm</v-btn>
                                        <v-btn v-if="isInsufficientFunds" rounded="0" elevation="2" color="button01" class="text-none text-grey05 body-p-small-b" width="300" height="66" disabled aria-label="Insufficient funds">Insufficient funds</v-btn>
                                    </div>
                                </div>
                                <!-- --------------------- CONFIRM ------------------------ -->
                                <div class="mt-7" v-if="action == 'CONFIRM'">
                                    <v-card max-width="560" class="ml-13 r-m-l-0 pa-8" elevation="12" :rounded="0">
                                        <i class="iconfont icon-refresh"></i>
                                        <h2 class="sidebar-h2 mt-5 text-primaryGrey">Waiting for Confirmation...</h2>
                                        <v-divider class="my-7"></v-divider>
                                        <h5 class="mt-3 body-p mb-16 text-primaryGrey">
                                            Confirm this transaction in your wallet.
                                        </h5>
                                    </v-card>
                                    <!-- 没有购买权限则禁用 -->
                                    <div class="mt-16 ml-13 r-m-l-0 gradient-left-red-purple-300">
                                        <v-btn v-if="!isInsufficientFunds" rounded="0" elevation="2" :disabled="!hasBuyPermission" :loading="buyLoading" color="button01" class="text-none text-grey05 body-p-small-b" width="300" height="66" @click="buy()" aria-label="Confirm">Confirm</v-btn>
                                        <v-btn v-if="isInsufficientFunds" rounded="0" elevation="2" color="button01" class="text-none text-grey05 body-p-small-b" width="300" height="66" disabled aria-label="Insufficient funds">Insufficient funds</v-btn>
                                    </div>
                                </div>
                                <!-- --------------------- BEING_CONFIRMED ------------------------ -->
                                <div class="mt-7" v-if="action == 'BEING_CONFIRMED'">
                                    <v-card max-width="560" class="ml-13 r-m-l-0 pa-8 pb-16" elevation="12" :rounded="0">
                                        <i class="iconfont icon-refresh text-primaryGrey"></i>
                                        <h2 class="sidebar-h2 mt-5 text-primaryGrey">Your purchase is being confirmed...</h2>
                                        <v-divider class="my-7"></v-divider>
                                        <h5 class="mt-3 body-p mb-16 text-primaryGrey">
                                            The purchase is being confirmed on the blockchain.
                                        </h5>
                                        <div class="gradient-underline-hover">
                                            <a :href="$tools.getBlockchainExplorerUrl(nft.blockchain) + '/tx/' + transactionHash" class="del-underline text-primaryGrey gradient-text-hover body-p" target="_blank">
                                                <i class="iconfont icon-export pr-3 text-primaryGrey"></i>View on {{ currentBlockchain.blockchainExplorer }}
                                            </a>
                                        </div>
                                    </v-card>
                                    <!-- 没有购买权限则禁用 -->
                                    <div class="mt-16 ml-13 r-m-l-0 gradient-left-red-purple-300">
                                        <v-btn rounded="0" elevation="2" aria-label="Loading" loading color="button01" class="text-none text-grey05 body-p-small-b" width="300" height="66"></v-btn>
                                    </div>
                                </div>
                                <!-- --------------------- COMPLETION ------------------------ -->
                                <div class="mt-7" v-if="action == 'COMPLETION'">
                                    <v-card max-width="560" class="ml-13 r-m-l-0 pa-8 pb-16" elevation="12" :rounded="0">
                                        <i class="mb-5 iconfont icon-complete"></i>
                                        <h2 class="sidebar-h2 mt-5 text-primaryGrey">Your purchase has been confirmed !</h2>
                                        <v-divider class="my-7"></v-divider>
                                        <h5 class="mt-3 body-p text-primaryGrey">
                                            Congratulations! 
                                        </h5>
                                        <h5 class="mt-3 body-p text-primaryGrey">
                                            You can view your unNFT in the wallet and on the Dashboard!
                                        </h5>
                                    </v-card>
                                    <div class="mt-16 ml-13 r-m-l-0">
                                        <router-link :to="'/profile/' + user.username" class="del-underline gradient-left-red-purple-300">
                                            <v-btn rounded="0" elevation="2" aria-label="Go to my profile" color="button01" class="text-none text-grey05 body-p-small-b" width="300" height="66">Go to my profile</v-btn>
                                        </router-link>
                                    </div>
                                </div>
                            </v-col>
                            <v-col cols="12" lg="5" class="ml-12 r-m-l-0 mt-10">
                                <UnNftBuy ref="unNftBuy" :nftId="id" :listInfo="nftListInfo"></UnNftBuy>
                                <Step process-status="process-text" finish-status="finish-text" :steps="steps" :action="action" class="mt-16 body-p-small"></Step>
                            </v-col>
                       </v-row>
                    </v-col>
                </v-row>
           </v-card>
        </v-container>
        <!-- 钱包组件 -->
        <MetaMask ref="MetaMask" @transactionClose="transactionClose"></MetaMask>
        <Reown ref="Reown" @transactionClose="transactionClose"></Reown>
	</div>
</template>
<script>
import { mapGetters } from "vuex";
import Web3 from "web3";
import NFTAPI from '@/api/nft.js'
import NFTProvenanceAPI from '@/api/nft-provenance.js'
import UnNftBuy from '@/components/nft/UnNftBuy'
export default {
    data(){
        return {
            // NFT 主键
            id: this.$route.params.id,
            // 操作
            action: 'BUY',
            // 步骤
            steps: [
                { title: 'Buy now', codes: ['BUY'] },
                { title: 'Confirm in your wallet', codes: ['CONFIRM', 'BEING_CONFIRMED'] },
                { title: 'Completion', codes: ['COMPLETION'] }
            ],
            // 简单NFT信息
            nft: {},
            // NFT 上市信息
            nftListInfo: {
                price: null,
                lister: null,
                isListed: false
            },
            // 钱包余额
            balance: 0,
            // 购买加载中
            buyLoading: false,
            // 交易收据定时器
            transactionReceiptInterval: null,
            // 交易哈希
            transactionHash: null,
            // 是否有购买权限
            hasBuyPermission: false,
            // 当前的区块链
            currentBlockchain: {},
            web3: {},
        }
    },
    components: { UnNftBuy },
    created(){
        // 监听
        this.$bus.on('callGetTransactionReceipt', data => {
            if (data.type == "NFT_BUY") {
                this.callGetTransactionReceipt(data.data);
            }
        })
    },
    mounted(){

    },
    computed: {
        ...mapGetters([ 'user', 'walletType', 'prices', 'blockchains']),
        // 总价格
        totalPrice() {
            return (this.prices.filter(currency => currency.currency == this.nft.paymentToken)[0]?.price || 0) * this.nftListInfo.price;
        },
        // 是否资金不足
        isInsufficientFunds() {
            return this.nftListInfo.price && this.nftListInfo.price >= this.balance;
        },
    },
    watch:{
        id: {
            handler(newVal, oldVal) {
                // 根据主键查询简单 NFT
                this.getSimpleNFTById();
            },
            immediate: true
        },
        'user.wallet': {
            handler(newVal, oldVal) {
                if(newVal && this.currentBlockchain.blockchain) {
                    // 查询钱包余额
                    this.getBalance();
                }
            },
            immediate: true
        }
    },
    methods: {
        // 根据主键查询简单 NFT
        async getSimpleNFTById() {
            if(this.id == null) {
                return;
            }
            // 判断用户是否有购买权限
            this.buyPermission();
            let res = await NFTAPI.getSimpleNFTById(this.id);
            let data = res.data;
            if(data.success) {
                this.nft = data.data;
                // 当前区块链
                this.currentBlockchain = this.blockchains.filter(b => b.blockchain == this.nft.blockchain)[0]
                // 创建 web3
                this.web3 = new Web3(new Web3.providers.HttpProvider(this.currentBlockchain.RPCUrl));
                // 查询钱包余额
                this.getBalance();
                // 查询上市信息
                this.getListInfo();
            } else {
                this.$store.dispatch('snackbarMessageHandler', data.message);
            }
        },
        // 查询上市信息
        async getListInfo() {
            let NFTContract = new this.web3.eth.Contract(this.currentBlockchain.unNFTAbi, this.currentBlockchain.unNFTContract);
            // {0: '0', 1: '0x0000000000000000000000000000000000000000', 2: false}
            let listInfo = await NFTContract.methods.getListInfo(this.nft.tokenId).call();
            this.nftListInfo.price = this.web3.utils.fromWei(listInfo[0]);
            this.nftListInfo.lister = listInfo[1];
            this.nftListInfo.isListed = listInfo[2];
        },
        // 查询钱包余额
        async getBalance() {
            let balance = await this.web3.eth.getBalance(this.user.wallet);
            this.balance = Number(this.web3.utils.fromWei(balance));
        },
        // 判断用户是否有购买权限
        async buyPermission() {
            if(this.id == null) {
                return;
            }
            let res = await NFTAPI.buyPermission(this.id);
            let data = res.data;
            if(data.success) {
                this.hasBuyPermission = data.data;
            }
        },
        // 链上购买
        async buy() {
            // 加载中
            this.buyLoading = true;
            // 购买时候再次查询一次上市状态和价格
            let NFTContract = new this.web3.eth.Contract(this.currentBlockchain.unNFTAbi, this.currentBlockchain.unNFTContract);
            // {0: '0', 1: '0x0000000000000000000000000000000000000000', 2: false}
            let listInfo = await NFTContract.methods.getListInfo(this.nft.tokenId).call();
            this.nftListInfo.price = this.web3.utils.fromWei(listInfo[0]);
            this.nftListInfo.lister = listInfo[1];
            this.nftListInfo.isListed = listInfo[2];
            // 如果已下市则中断
            if(!this.nftListInfo.isListed) {
                this.buyPermission();
                this.buyLoading = false;
                return;
            }
            // 确认中
            this.action = 'CONFIRM';
            // 编码参数列表
            try {
                let method  = this.web3.eth.abi.encodeFunctionSignature('buy(uint256)');
                // 将参数编码
                let priceWei = this.web3.utils.toWei(this.nftListInfo.price + '');
                let param = this.web3.eth.abi.encodeParameters(['uint256'], [this.nft.tokenId]).substring(2);
                let priceHexString = this.web3.eth.abi.encodeParameter('uint256', priceWei);
                // 组装数据
                let data = method + param;
                // 价格：WalletConnect - wei; MetaMask - Hex String
                let value = this.walletType == 'WalletConnect' ? priceWei : priceHexString;
                // 调起钱包发送交易
                if(this.walletType){
                    this.$refs[this.walletType].sendTransaction(this.nft.blockchain, this.user.wallet, this.currentBlockchain.unNFTContract, data, value);
                } else {
                    // 错误通知
                    this.$store.dispatch('snackbarMessageHandler', "Invalid wallet type");
                    // 加载完成
                    this.buyLoading = false;
                }
            } catch (error) {
                console.error(error);
                this.$store.dispatch('snackbarMessageHandler', error);
                // 加载完成
                this.buyLoading = false;
                // 失败后回到上一步
                this.action = 'BUY';
            }
        },
        // 交易关闭：成功/失败
        async transactionClose(success, transactionHash) {
            // 保存交易历史
            this.transactionHash = transactionHash;
            // 交易成功
            if(success) {
                // 保存在数据库
                // await this.mint();
                // 成功后进入下一步
                this.action = 'BEING_CONFIRMED';
                // 链下购买 NFT
                // 20230912: 服务端订阅合约，前端取消请求
                // this.buyOnUntrading();
                // 查询交易收据
                this.getTransactionReceipt();
            } else {
                // 失败后回到上一步
                this.action = 'BUY';
            }
            // 加载完成
            this.buyLoading = false;
        },
        // 查询交易收据
        getTransactionReceipt(){
            // 轮训查询交易
            this.transactionReceiptInterval = setInterval(() => {
                let params = {
                    type: "NFT_BUY",
                    blockchain: this.currentBlockchain.blockchain,
                    transactionHash: this.transactionHash
                }
                this.$bus.emit('emitGetTransactionReceipt', params);
            }, 3000)
        },
        // 回调交易收据
        callGetTransactionReceipt(data) {
            // 当交易还在 Pending 中的时候，data 为 null
            // 所以data不为null时候代表交易已经完成：可能成功，可能失败
            if(data) {
                // 清除定时器
                clearInterval(this.transactionReceiptInterval);
                // 无论交易成功还是失败，都转到下一步
                this.action = 'COMPLETION';
                // 根据主键查询简单 NFT
                this.getSimpleNFTById();
                // 刷新子组件数据
                this.$refs.unNftBuy.getNFTById();
            }
        },
        // 链下购买，更新数据库
        async buyOnUntrading() {
            // 购买 NFT
            let params = {
                blockchain: this.nft.blockchain,
                transactionHash: this.transactionHash,
                price: this.nftListInfo.price,
                paymentToken: this.nft.paymentToken,
                nftId: this.id
            }
            let res = await NFTAPI.buy(params);
            let data = res.data;
            if(data.success) {
                // 购买成功，刷新信息
                if(data.data) {
                    // 根据主键查询简单 NFT
                    this.getSimpleNFTById();
                    // 刷新子组件数据
                    this.$refs.unNftBuy.getNFTById();
                }
            }
        },
    },
    beforeRouteLeave(to, from, next) {
        // 在路由离开时清除定时器
        if (this.transactionReceiptInterval) {
            clearInterval(this.transactionReceiptInterval);
        }
        next();
    }
}
</script>
<style scoped>

</style>