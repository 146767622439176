<template>
    <div>
        <v-container class="align-center content r-p-x-0"  id="fadeIn" :style="`margin-bottom: ${footerHeight}px`">
            <v-container class="mx-0-auto r-p-x-0 bg-black">
                <v-row no-gutters align="end" justify="center" class="hero_section pb-200">
                    <div class="layoutContentCal">
                        <div class="Grid_root__iRfoa grid-style-cal">
                            <div class="Grid_a__vY7M8 d-flex align-center">
                                <div class="lexend-deca prose text-tertiary">
                                    <p>The Untrading Extra Earnings Calculator creates an estimate of how much additional profit you could earn using Untrading, on a single transaction. </p>
                                    <ul class="pl-8">
                                        <li>Input your buy and sell prices</li>
                                        <li>Adjust Profit Expectancy levels</li>
                                        <li>See potential earnings across multiple future sales</li>
                                    </ul>
                                    <p class="fs-17 text-tertiary gradient-underline-hover">The <a href="https://5173.foundation/" target="_blank" class="del-underline link-underline-hover text-tertiary pointer gradient-text-hover">
                                        <span class="gradient-text-hover">Advanced Calculator</span></a> allows you to go more in-depth
                                    </p>
                                </div>
                            </div>
                            <div class="Grid_b___vQi_  r-p-b-16">
                                <Calculator class="glow-effect"></Calculator>
                            </div>
                        </div>
                    </div>
                </v-row>
                <!-- CryptoScreenshot -->
                <v-row no-gutters class="bg-homeBg py-300 r-p-y-150" justify="center" align="center">
                    <v-col cols="12" sm="12" md="12" xl="12" xxl="12" class="px-0">
                        <NftScreenshot class="glow-effect"></NftScreenshot>
                    </v-col>
                </v-row>
            </v-container>
            <v-container class="pa-0 r-p-x-0">
                <v-row no-gutters justify="center" align="center" class="py-300 r-p-y-150 bg-black">
                    <Redefining></Redefining>
                </v-row>
                <div class="py-300 r-p-y-150 bg-homeBg">
                    <Communities class="glow-effect"></Communities>
                </div>
                <v-row no-gutters justify="center" align="center" class="py-300 r-p-y-150 bg-black glow-effect">
                    <Revolution></Revolution>
                </v-row>
                <v-row no-gutters justify="center" align="center" class="py-300 r-p-y-150 bg-homeBg">
                    <FeeConflict></FeeConflict>
                </v-row>
                <v-row no-gutters justify="center" align="center" class="py-300 r-p-y-150 bg-black glow-effect">
                    <ProfitExpectancy></ProfitExpectancy>
                </v-row>
                <v-row no-gutters justify="center" align="center" class="py-300 r-p-y-150 bg-homeBg glow-effect">
                    <Beyond></Beyond>
                </v-row>
                <v-row no-gutters justify="center" align="center" class="py-300 r-p-y-150 bg-black glow-effect">
                    <Customize></Customize>
                    <div class="revolution-glow-bottom-left"></div>
                </v-row>
                <v-row no-gutters class="py-300 r-p-y-150 bg-homeBg" justify="center" align="center">
                    <div class="layoutContent">
                        <v-col cols="12" sm="12" md="12" class="px-0">
                            <h2 class="lexend-deca typography_h1 text-quaternary">FAQs</h2>
                        </v-col>
                        <v-col cols="12" sm="12" md="12" class="px-0 mt-8">
                            <Faq></Faq>
                            <div class="mt-16">
                                <a href="https://docs.untrading.org/faqs" target="_blank" type="button" class="px-4 button_root button_variant-secondary button_size-small button_variant link_root" rel="noopener">
                                    <span class="text-tertiary" style="padding: 0px;">More</span>
                                    <v-icon class="text-black01">mdi mdi-chevron-right</v-icon>
                                </a>
                            </div>
                        </v-col>
                    </div>
                </v-row>
                <v-row no-gutters class="py-300 r-p-y-150 bg-black" justify="center" align="center">
                    <GetStarted></GetStarted>
                </v-row>
            </v-container>
        </v-container>
        <v-container class="footer r-p-x-0" ref="footer">
            <!-- Footer -->
            <v-row no-gutters justify="center" class="bg-homeBg">
                <v-col cols="12" sm="12" md="8">
                    <Footer></Footer>
                </v-col>
            </v-row>
            <v-divider></v-divider>
            <v-row no-gutters justify="center" class="bg-homeBg" v-if="responsive == 'pc'">
                <v-col cols="12" sm="12" md="8" xl="8" class="px-0">
                    <v-row no-gutters justify="start" align="center">
                        <v-col cols="12" sm="12" md="6" xl="6" xxl="6" class="px-0 my-2 d-flex justify-end align-center">
                            <v-row no-gutters>
                                <div class="gradient-underline-hover footer-14 r-m-t-8">
                                    <a href="https://docs.untrading.org/terms-of-service" target="_blank" class="text-primaryGrey del-underline">
                                    <span class="gradient-text-hover">Terms of Service</span>
                                    </a>
                                </div>
                                <div class="gradient-underline-hover footer-14 r-m-t-8 px-16 r-p-x-0">
                                    <a href="https://docs.untrading.org/privacy-policy" target="_blank" class="text-primaryGrey del-underline">
                                    <span class="gradient-text-hover">Privacy Policy</span>
                                    </a>
                                </div>
                                <div class="gradient-underline-hover footer-14 r-m-t-8">
                                    <a href="https://drive.google.com/drive/folders/11nDyyHIT7q8xBmoMrOa4Y7IwhNpzPBPj?usp=sharing" target="_blank" class="text-primaryGrey del-underline">
                                    <span class="gradient-text-hover">Media kit</span>
                                    </a>
                                </div>
                            </v-row>
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>
<script>
    import { mapGetters } from "vuex";
    import Beyond from './v2/components/Beyond.vue';
    import Calculator from './v2/components/Calculator.vue';
    import Communities from './v2/components/Communities.vue';
    import Customize from './v2/components/Customize.vue';
    import Faq from './v2/components/Faq.vue';
    import FeeConflict from './v2/components/FeeConflict.vue';
    import Footer from '@/views/home/v2/components/Footer.vue';
    import GetStarted from './v2/components/GetStarted.vue';
    import NftScreenshot from './v2/components/NftScreenshot.vue';
    import ProfitExpectancy from './v2/components/ProfitExpectancy.vue';
    import Redefining from './v2/components/Redefining.vue';
    import Revolution from './v2/components/Revolution.vue';
    export default {
        data(){
            return {
                scroll: 0,
                footerHeight: 0,
            }
        },
        components: {Beyond, Calculator, Communities, Customize, Faq, FeeConflict,Footer, GetStarted, NftScreenshot, ProfitExpectancy, Redefining, Revolution},
        created(){
    
        },
        mounted() {
            this.calculateFooterHeight(); // 页面加载时计算 footer 高度
            window.addEventListener('resize', this.calculateFooterHeight); // 监听窗口大小变化
        },
        beforeDestroy() {
            window.removeEventListener('resize', this.calculateFooterHeight); // 移除事件监听
        },
        computed: {
             ...mapGetters(["darkTheme",'responsive'])
        },
        watch:{
    
        },
        methods: {
            calculateFooterHeight() {
                const footer = this.$refs.footer; // 访问组件的引用
                if (footer && footer.$el) { // 确保 footer 和 $el 都存在
                    this.footerHeight = footer.$el.offsetHeight; // 获取 footer 高度
                    console.log('Footer height:', this.footerHeight);
                } else {
                    console.warn('Footer reference or $el is null');
                }
            },
        }
    }
</script>
<style scoped>
    .content {
    flex: 1;
    /* 自适应填充剩余空间 */
    position: relative;
    z-index: 1;
    /* 确保内容在 footer 之上 */
    }
    .footer {
    position: fixed;
    /* 使用固定定位 */
    bottom: 0;
    /* 使 footer 固定在底部 */
    z-index: 0;
    /* 确保 footer 在内容之下 */
    }
    @-webkit-keyframes fadeIn {
    0% {
    opacity: 0;
    /*初始状态 透明度为0*/
    }
    50% {
    opacity: 0.3;
    /*中间状态 透明度为0*/
    }
    100% {
    opacity: 1;
    /*结尾状态 透明度为1*/
    }
    }
    #fadeIn {
    -webkit-animation-name: fadeIn;
    /*动画名称*/
    -webkit-animation-duration: 0.5s;
    /*动画持续时间*/
    -webkit-animation-iteration-count: 1;
    /*动画次数*/
    }
</style>