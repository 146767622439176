<template>
    <div class="lexend-deca">
        <!-- <div class="extra-glow-crypto-content"></div> -->
        <v-row no-gutters align="start" justify="center">
            <v-col cols="12" sm="12" md="3" lg="3" xl="3" xxl="2" class="d-flex flex-column px-0 c_inside r-p-x-6 mt-16 show-mobile">
                <div class="hide-mobile">
                    <div v-show="this.darkTheme == 0">
                        <Logo type="unCryptos" app="unCryptos" color="light" responsive="pc" size="120"></Logo>
                    </div>
                    <div v-show="this.darkTheme == 1" >
                        <Logo type="unCryptos" app="unCryptos" color="dark" responsive="pc" size="120"></Logo>
                    </div>
                </div>
                <div class="show-mobile d-flex justify-center">
                    <div v-show="this.darkTheme == 0">
                        <Logo type="unCryptos" app="unCryptos" color="light" responsive="pc" size="120"></Logo>
                    </div>
                    <div v-show="this.darkTheme == 1" >
                        <Logo type="unCryptos" app="unCryptos" color="dark" responsive="pc" size="120"></Logo>
                    </div>
                </div>
                <div class="Spacer_root__uoSvA" style="--height:24px"></div>
                <h2 class="text-quaternary typography_h1 text-center">
                    Earn Rewards Even After Selling
                </h2>
                <div class="Spacer_root__uoSvA" style="--height:24px"></div>
                <p class="text-tertiary fs-17 text-center text-balance">
                    Invest in top cryptocurrencies and earn rewards that continue even after selling. Join 68,000+ users creating lasting wealth together.
                </p>
                <div class="Spacer_root__uoSvA" style="--height:36px"></div>
                <div class="text-center">
                    <a href="https://testnet.untrading.org/uncryptos" class="del-underline">
                    <button class="btn button_root button_size-default button_variant">Launch unCryptos</button>
                    </a>
                </div>
                <div class="Spacer_root__uoSvA" style="--height:36px"></div>
                <div class="text-center"> 
                    <a href="/features" type="button" class="button_root button_variant-secondary button_size-default button_variant link_root" rel="noopener">
                        <span class="text-tertiary" style="padding: 0px;">Start Learning</span>
                        <v-icon class="text-black01">mdi mdi-chevron-right</v-icon>
                    </a>
                </div>
            </v-col>
            <v-col cols="12" sm="12" md="9" lg="9" xl="9" xxl="5" class="px-0 mt-8">
                <v-img contain class="cryptoImg r-p-l-0" aspect-ratio="1" src="https://imagedelivery.net/P4z5C24T1A4q673nx_eYTw/768cf59c-3c79-41c2-30de-b3bba01fa800/public"></v-img>
                <div class="show-mobile r-p-x-6">
                    <div class="Spacer_root__uoSvA" style="--height:32px"></div>
                    <div class="ga-2 d-flex flex-column">
                        <h3 class="fs-21-bold text-secondary">
                            No Fees, No Predictions
                        </h3>
                        <div class="Spacer_root__uoSvA" style="--height:4px"></div>
                        <p class="text-tertiary fs-17">
                            Grow your profits through participation with Future Rewards.
                        </p>
                    </div>
                    <div class="Spacer_root__uoSvA" style="--height:32px"></div>
                    <div class="ga-2 d-flex flex-column">
                        <h3 class="fs-21-bold text-secondary">
                            Full Control
                        </h3>
                        <div class="Spacer_root__uoSvA" style="--height:4px"></div>
                        <p class="text-tertiary fs-17 gradient-underline-hover">
                            Keep your crypto in your wallet while earning risk-free "<a href="https://5173.foundation/" target="_blank" class="pointer text-tertiary del-underline link-underline-hover"><span class="gradient-text-hover">variable annuity</span></a>" rewards through Flows.
                        </p>
                    </div>
                </div>
            </v-col>
            <v-col cols="12" sm="12" md="3" lg="3" xl="3" xxl="2" class="d-flex flex-column px-0 c_inside r-p-x-6 mt-16 hide-mobile">
                <div class="hide-mobile">
                    <div v-show="this.darkTheme == 0">
                        <Logo type="unCryptos" app="unCryptos" color="light" responsive="pc" size="120"></Logo>
                    </div>
                    <div v-show="this.darkTheme == 1" >
                        <Logo type="unCryptos" app="unCryptos" color="dark" responsive="pc" size="120"></Logo>
                    </div>
                </div>
                <div class="show-mobile">
                    <div v-show="this.darkTheme == 0">
                        <Logo type="unCryptos" app="unCryptos" color="light" responsive="pc" size="120"></Logo>
                    </div>
                    <div v-show="this.darkTheme == 1" >
                        <Logo type="unCryptos" app="unCryptos" color="dark" responsive="pc" size="120"></Logo>
                    </div>
                </div>
                <div class="Spacer_root__uoSvA" style="--height:24px"></div>
                <h2 class="text-quaternary typography_h1">
                    Earn Rewards Even After Selling
                </h2>
                <div class="Spacer_root__uoSvA" style="--height:24px"></div>
                <p class="text-tertiary fs-17">
                    Invest in top cryptocurrencies and earn rewards that continue even after selling. Join 68,000+ users creating lasting wealth together.
                </p>
                <div class="Spacer_root__uoSvA" style="--height:36px"></div>
                <div>
                    <a href="https://testnet.untrading.org/uncryptos" class="del-underline">
                    <button class="btn button_root button_size-default button_variant">Launch unCryptos</button>
                    </a>
                </div>
                <div class="Spacer_root__uoSvA" style="--height:36px"></div>
                <div>
                    <a href="/features" type="button" class="button_root button_variant-secondary button_size-default button_variant link_root" rel="noopener">
                        <span class="text-tertiary" style="padding: 0px;">Start Learning</span>
                        <v-icon class="text-black01">mdi mdi-chevron-right</v-icon>
                    </a>
                </div>
                <div class="Spacer_root__uoSvA" style="--height:128px"></div>
                <div class="ga-2 d-flex flex-column">
                    <h3 class="fs-21-bold text-secondary">
                        No Fees, No Predictions
                    </h3>
                    <div class="Spacer_root__uoSvA" style="--height:4px"></div>
                    <p class="text-tertiary fs-17">
                        Grow your profits through participation with Future Rewards.
                    </p>
                </div>
                <div class="Spacer_root__uoSvA" style="--height:32px"></div>
                <div class="ga-2 d-flex flex-column">
                    <h3 class="fs-21-bold text-secondary">
                        Full Control
                    </h3>
                    <div class="Spacer_root__uoSvA" style="--height:4px"></div>
                    <p class="text-tertiary fs-17 gradient-underline-hover text-balance">
                        Keep your crypto in your wallet while earning risk-free "<a href="https://5173.foundation/" target="_blank" class="pointer text-tertiary del-underline link-underline-hover"><span class="gradient-text-hover">variable annuity</span></a>" rewards through Flows.
                    </p>
                </div>
            </v-col>
        </v-row>
    </div>
</template>
<script>
    import Logo from '@/components/common/Logo';
    import { mapGetters } from "vuex";
    export default {
        data(){
            return {
    
            }
        },
        components: { Logo },
        created(){
    
        },
        mounted(){
    
        },
        computed: {
            ...mapGetters(['darkTheme']),
        },
        watch:{
    
        },
        methods: {
            
        }
    }
</script>
<style scoped></style>