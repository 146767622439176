<template>
	<div>
        <!-- 弹窗组件 -->
        <v-dialog v-model="claimFRDialog" content-class="v-echarts-dialog" :persistent="claimed" width="500">
            <v-card class="pa-8" rounded="0" elevation="12" color="white01">
                <v-row no-gutters>
                    <v-col cols="12">
                        <v-card-title class="d-flex px-0">
                            <div class="sidebar-h2 text-primaryGrey mt-3">
                                <span v-if="!claimed">Claim FRs</span>
                                <span v-if="claimed">Claimed!</span>
                            </div>
                            <v-spacer></v-spacer>
                            <v-icon aria-label="close" icon="mdi:mdi-close" class="text-primaryGrey" @click="closeClaimFRDialog"></v-icon>
                        </v-card-title>
                        <v-divider class="my-4"></v-divider>
                        <!-- --------------------  领取 ---------------------- -->
                        <div v-if="!claimed">
                            <div class="body-h4 text-primaryGrey mt-8 mb-3">Blockchain</div>
                            <v-text-field v-model="stat.blockchain" variant="solo" density="comfortable" hide-details readonly>
                                <template v-slot:prepend-inner>
                                    <Blockchain :blockchain="stat.blockchain" size="24"></Blockchain>
                                </template>
                            </v-text-field>
                            <div class="body-h4 text-primaryGrey mt-8 mb-3">Account/Wallet</div>
                            <v-text-field v-model="user.wallet" variant="solo" density="comfortable" hide-details readonly></v-text-field>
                            <div class="body-h4 text-primaryGrey mt-8 mb-3">
                                <span>Total Unclaimed FRs</span>
                                <v-menu transition="slide-y-transition" open-on-hover :offset="10" location="top">
                                    <template v-slot:activator="{ props }" >
                                        <v-icon icon="mdi:mdi-alert-circle-outline" class="ml-1 text-primaryGrey pointer gradient-text-hover" v-bind="props"></v-icon>
                                    </template>
                                    <v-card max-width="420" class="pa-3 text-primaryGrey body-p" rounded="0">
                                        Minimum claim amount 0.05
                                    </v-card>
                                </v-menu>
                            </div>
                            <div class="body-p text-primaryGrey mb-3"></div>
                            <v-row no-gutters>
                                <v-col cols="8">
                                    <v-text-field :value="$tools.formatNumber(stat.totalUnclaimedFRs, stat.paymentTokenSizeDecimals)" variant="solo" density="comfortable" hide-details readonly></v-text-field>
                                </v-col>
                                <v-col cols="4">
                                    <v-text-field :value="stat.paymentToken" variant="solo" density="comfortable" hide-details readonly></v-text-field>
                                </v-col>
                            </v-row>
                            <div class="text-center mb-5 mt-16">
                                <a class="del-underline gradient-left-red-purple-200">
                                    <v-btn rounded="0" elevation="2" :disabled="stat.totalUnclaimedFRs < 0.05" :loading="loading" color="button01" class="text-none text-grey05 body-p-small-b mt-3" width="200" height="52" @click="confirm" aria-label="Confirm">Confirm</v-btn>
                                </a>
                            </div>
                        </div>
                        <!-- --------------------  已领取 ---------------------- -->
                        <div v-if="claimed">
                            <div class="mt-3 body-p text-primaryGrey">
                                Congratulations! Your rewards have been claimed to your wallet.
                            </div>
                            <div class="mt-16 mb-5 gradient-underline-hover">
                                <a :href="$tools.getBlockchainExplorerUrl(stat.blockchain) + '/tx/' + transactionHash" class="del-underline text-primaryGrey gradient-text-hover body-p pointer" target="_blank">
                                    <i class="iconfont icon-export pr-3 text-primaryGrey"></i>View on {{ currentBlockchain.blockchainExplorer }}
                                </a>
                            </div>
                        </div>
                    </v-col>
                </v-row>
            </v-card>
        </v-dialog>
        <!-- 钱包组件 -->
        <MetaMask ref="MetaMask" @transactionClose="transactionClose"></MetaMask>
        <Reown ref="Reown" @transactionClose="transactionClose"></Reown>
	</div>
</template>
<script>
import { mapGetters } from "vuex";
import Web3 from "web3";
export default {
    data(){
        return {
            // 弹窗
            claimFRDialog: false,
            // 加载中
            loading: false,
            // 交易哈希
            transactionHash: null,
            // 已领取
            claimed: false,
            // 当前的区块链
            currentBlockchain: {},
        }
    },
    props: {
        show: {
            type: Boolean,
            default: false
        },
        stat: {
            type: Object,
            default: {
                blockchain: null,
                contractAddress: null,
                paymentToken: null,
                paymentTokenAddress: null,
                paymentTokenSizeDecimals: 2,
                totalUnclaimedFRs: 0
            }
        }
    },
    components: {  },
    created(){

    },
    mounted(){

    },
    computed: {
        ...mapGetters(['user', 'walletType', 'blockchains'])
    },
    watch:{
        show: {
            handler(n, o) {
                if(n) {
                    this.claimFRDialog = true;
                } else {
                    this.claimFRDialog = false;
                }
            },
            immediate: true
        },
        claimFRDialog: {
            handler(n, o) {
                if(!n) {
                    this.$emit('watchClaimFRDialog', false);
                }
            },
            immediate: true
        },
        stat: {
            handler(n, o) {
                if(n) {
                    this.currentBlockchain = this.blockchains.filter(b => b.blockchain == n.blockchain)[0];
                } else {
                    this.currentBlockchain = this.blockchains[0];
                }
            },
            immediate: true
        }
    },
    methods: {
        // 确认领取，调起钱包
        async confirm() {
            this.loading = true;
            let web3 = new Web3(new Web3.providers.HttpProvider(this.currentBlockchain.RPCUrl));
            // 编码参数列表
            try {
                // 将函数编码
                let method  = web3.eth.abi.encodeFunctionSignature('releaseAllottedTokens(address,address)');
                // 将参数编码
                let param = web3.eth.abi.encodeParameters(['address', 'address'], [this.user.wallet, this.stat.paymentTokenAddress]).substring(2);
                // 组装数据
                let data = method + param;
                // 调起钱包发送交易
                if(this.walletType){
                    this.$refs[this.walletType].sendTransaction(this.stat.blockchain, this.user.wallet, this.stat.contractAddress, data);
                } else {
                    // 错误通知
                    this.$store.dispatch('snackbarMessageHandler', "Invalid wallet type");
                    // 加载完成
                    this.loading = false;
                }
            } catch (error) {
                console.error(error);
                this.$store.dispatch('snackbarMessageHandler', error);
                // 加载完成
                this.loading = false;
            }
        },
        // 交易关闭：成功/失败
        async transactionClose(success, transactionHash) {
            this.transactionHash = transactionHash;
            // 交易成功
            if(success) {
                this.claimed = true;
            }
            // 加载完成
            this.loading = false;
        },
        // 关闭弹窗
        closeClaimFRDialog() {
            // 关闭弹窗
            this.$emit('watchClaimFRDialog', false);
            // 如果已领取，需要刷新数据
            if(this.claimed) {
                // 取消领取
                this.claimed = false;
                this.$emit('emitGetDashboards', null);
            }
        }
    }
}
</script>
<style scoped>
:deep(.v-echarts-dialog){
    width: auto;
}
</style>