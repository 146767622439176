<template>
	<div>
        <div v-show="show">
            <div class="text-primaryGrey mb-3">
                <v-row no-gutters>
                    <v-col cols="auto">
                        <div class="d-flex flex-wrap">
                            <div class="body-text-btn d-flex align-center">
                                <v-icon v-if="currencyIcon" size="24" class="mr-2">
                                    <v-img :src="currencyIcon"></v-img>
                                </v-icon>
                                <div>{{ paymentToken == null ? '--/--' : `${currency}/${paymentToken}` }}</div>
                            </div>
                        </div>
                    </v-col>
                    <v-spacer></v-spacer>
                    <v-col cols="auto" class="float-right body-text-btn" justify="end">
                        {{ $tools.formatNumber(currentPrice, 6) }}
                    </v-col>
                </v-row>
            </div>
            <div :id="id" style="width: 100%; position: relative;" :style="{ height: responsive == 'pc' ? '480px' : '240px' }"></div>
        </div>
	</div>
</template>
<script>
import { mapGetters } from "vuex";
import Web3 from "web3";
import CryptoProvenanceAPI from '@/api/crypto-provenance.js';
export default {
    data(){
        return {
            id: `cryptoPriceLineChart-${+new Date()}`,
            // 是否展示图表
            show: true,
            cryptoPriceLineChart: null,
            paymentToken: null,
            lastPrice: 0,
            currentPrice: 0,
            option : {
                xAxis: {
                    type: 'category',
                    axisTick: {
                        show: false
                    },
                    splitLine:{
                        show: false
                    },
                    axisLine: {
                        show: false
                    },
                    axisLabel: {
                        show: false,
                    },
                    data: []
                },
                grid: {
                    left: '1%',
                    right: '0%',
                    top: '10%',
                    bottom: '10%',
                    containLabel: true
                },
                yAxis: {
                    type: 'value',
                    axisTick: {
                        show: false,
                    },
                    splitLine:{
                        show: false
                    },
                    axisLine: {
                        show: false,
                    },
                    axisLabel: {
                        show: true,
                        align: "right",
                        formatter: function (value, index) {
                            return value.toFixed(6);
                        }
                    },
                    scale: true
                },
                tooltip: {
                    show: true,
                    trigger: 'item',
                    valueFormatter: (value) => value.toFixed(6)
                },
                series: [{
                    data: [],
                    type: 'line',
                    smooth: true,
                    symbolSize: 8,
                    symbol: 'circle',
                    itemStyle: {
                        color: "#03DAC5",
                    },
                    lineStyle: {
                        color: '#03DAC5',
                        width: 5
                    },
                }]
            },
            currencies: [
                { currency: 'BTC', icon: 'https://imagedelivery.net/P4z5C24T1A4q673nx_eYTw/b5847ad9-b2bf-491d-6a86-3d9d057d3500/public' },
                { currency: 'ETH', icon: 'https://imagedelivery.net/P4z5C24T1A4q673nx_eYTw/910c7fc2-83fe-4178-7f0f-5fb45f413500/public' },
                { currency: 'SOL', icon: 'https://imagedelivery.net/P4z5C24T1A4q673nx_eYTw/55dbc288-bf5c-4f71-fe33-f1c222388300/public' },
                { currency: 'LINK', icon: 'https://imagedelivery.net/P4z5C24T1A4q673nx_eYTw/edc96e8e-432c-4f73-3e4e-f7c88b2efb00/public' },
                { currency: 'UNI', icon: 'https://imagedelivery.net/P4z5C24T1A4q673nx_eYTw/9305484c-532d-4d15-6dc1-ccadc99db900/public' },
                { currency: 'UN', icon: 'https://imagedelivery.net/P4z5C24T1A4q673nx_eYTw/8d5cfb8b-4b67-4b8b-00b8-ed4187d98600/public' },
                { currency: 'UN²', icon: 'https://imagedelivery.net/P4z5C24T1A4q673nx_eYTw/421fb238-27d5-4619-4df1-e6bd427acb00/public' },
            ]
        }
    },
    props: {
        // 货币
        currency: {
            type: String,
            default: null
        },
        cryptoId: {
            type: String,
            default: null
        },
        tokenId: {
            type: [Number, String],
            default: null
        }
    },
    components: {  },
    created() {
      
    },
    mounted(){
       
    },
    computed: {

    },
    computed: {
        ...mapGetters(['darkTheme', 'responsive']),
        currencyIcon() {
            return this.currencies.filter(c => c.currency == this.currency)[0]?.icon || null;
        }
    },
    watch:{
        cryptoId: {
            handler(newVal, oldVal) {
                this.$nextTick(() => {
                    this.initFN();
                });
            },
            immediate: true
        },
    },
    methods: {
        // 初始化
        initFN() {
            var that = this;
            // 渲染图表
            this.cryptoPriceLineChart = this.$echarts.init(document.getElementById(this.id));
            // 填充数据
            this.cryptoPriceLineChart.setOption(this.option, true);
            this.cryptoPriceLineChart.on('click', function(series) {

            })
            // 获取价格折线图数据
            this.getPriceLineChart();
        },
        // 获取价格折线图数据
        async getPriceLineChart() {
            setTimeout( async () => {
                let res = await CryptoProvenanceAPI.getPriceHistories(this.cryptoId);
                let data = res.data;
                if(data.success) {
                    let chartResult = data.data;
                    this.paymentToken = chartResult.paymentToken;
                    if(chartResult.data.length == 0) {
                        chartResult.data = [0];
                    }
                    this.option.series[0].data = chartResult.data;
                    // 计算最小值/最大值/间隔
                    let minValue = Math.min(...chartResult.data);
                    let maxValue = Math.max(...chartResult.data);
                    if(minValue == maxValue){
                        this.option.yAxis.min = 0;
                        this.option.yAxis.max = minValue * 2;
                        this.option.yAxis.interval = minValue > 0 ? (minValue * 2) : 1;
                    } else {
                        this.option.yAxis.min = minValue;
                        this.option.yAxis.max = maxValue;
                        this.option.yAxis.interval = maxValue - minValue;
                    }
                    this.currentPrice = chartResult.data[chartResult.data.length - 1];
                } else {
                    // 查询失败不显示图表
                    this.show = false;
                }
                // 填充数据
                this.cryptoPriceLineChart.setOption(this.option, true);
            }, 100);
            
        },
    }
}
</script>
<style lang="scss" scoped>
// .cryptoPriceLineChart {
//     width: 400px;
//     height: 486px;
//     position: relative;
// }
</style>