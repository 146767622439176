<template>
	<div>
        <!-- 钱包网络错误 -->
        <v-dialog v-model="invaildNetworkDialog" persistent content-class="v-echarts-dialog">
            <v-card width="462" class="pa-8" color="pinkTone" rounded="0" elevation="12">
                <v-row no-gutters>
                    <v-col>
                        <v-row no-gutters align="center">
                            <v-col cols="12">
                                <div class="sidebar-h2 text-primaryGrey">Invaild blockchain network</div>
                            </v-col>
                        </v-row>
                        <v-divider class="my-4"></v-divider>
                        <v-col cols="12" class="my-2 px-0">
                            <div class="body-p text-primaryGrey mb-16">Please switch {{ currentBlockchain.blockchain ? `"${currentBlockchain.blockchain}"` : '' }} network in your wallet.</div>
                            <div class="mt-16 text-center">
                                <a class="del-underline gradient-left-red-purple-100">
                                    <v-btn :rounded="0" elevation="2" width="100" height="48" color="button01" class="text-none text-grey05 body-p-small-b" aria-label="OK" @click="closeInvaildNetworkDialog()">OK</v-btn>
                                </a>
                            </div>
                        </v-col>
                    </v-col>
                </v-row>
            </v-card>
        </v-dialog>
    </div>
</template>
<script>
// npm install @reown/appkit@1.6.4
// npm install @reown/appkit-adapter-ethers5@1.6.4
// npm install ethers@5.7.2
import { mapGetters } from "vuex";
import Web3 from "web3";
import * as Ethers from "ethers";
import UserAPI from '@/api/user.js';
import { createAppKit, useAppKit, useAppKitProvider, useAppKitAccount, useAppKitNetwork } from '@reown/appkit/vue';
import { arbitrum, polygon, mainnet, polygonMumbai, sepolia, goerli } from '@reown/appkit/networks';
import { Ethers5Adapter } from '@reown/appkit-adapter-ethers5'
export default {
    data(){
        return {
            appKitModal: null,
            // 项目ID：https://cloud.walletconnect.com/app
            projectId: '377c21880980cbabf9572130306e9326',
            metadata: {
                name: 'Untrading',
                url: window.location.origin, // origin must match your domain & subdomain
                icons: ['https://untrading.org/img/icons/favicon.ico']
            },
            // Supported Chains - https://docs.reown.com/cloud/chains/chain-list
            // 支持的区块链：https://wagmi.sh/core/api/chains#available-chains
            networks: [arbitrum, polygon, mainnet, polygonMumbai, sepolia, goerli],
            // 无效的网络弹窗
            invaildNetworkDialog: false,
            // 当前交易的区块链
            currentBlockchain: {}
        }
    },
    components: { },
    created(){
        this.createConfig(null);
    },
    mounted(){

    },
    computed: {
        ...mapGetters(['walletAddress', 'blockchains', 'darkTheme'])
    },
    watch:{

    },
    methods: {
        // 创建配置
        createConfig(chainId) {
            // Introduction - https://docs.reown.com/appkit/vue/core/installation
            // Demo - https://demo.reown.com
            // Create a AppKit instance
            const ethers5Adapter = new Ethers5Adapter({
                networks: this.networks,
                projectId: this.projectId,
            });
            // 缓存网络
            const network = useAppKitNetwork().value;
            // 设置默认网络
            let defaultNetwork = this.networks[0];
            if (network.chainId) {
                defaultNetwork = this.networks.filter(chain => chain.id == network.chainId)[0];
            } else if (chainId) {
                defaultNetwork = this.networks.filter(chain => chain.id == chainId)[0];
            }
            // Options - https://docs.reown.com/appkit/vue/core/options
            // Advanced Options - https://docs.reown.com/advanced/walletconnectmodal/options
            // Create the modal
            const appKitModal = createAppKit({
                enableWalletConnect: true,
                debug: false,
                adapters: [ethers5Adapter],
                defaultNetwork: defaultNetwork,
                networks: this.networks,
                projectId: this.projectId,
                metadata: this.metadata,
                // features - https://docs.reown.com/appkit/vue/core/options#features
                features: {
                    analytics: true,
                    swaps: false,
                    email: false,
                    socials: false,
                    connectMethodsOrder: ['wallet'],
                },
                // AllWallets - https://docs.reown.com/appkit/vue/core/options#allwallets
                allWallets: 'SHOW',
                // themeMode - https://docs.reown.com/advanced/walletconnectmodal/theming#thememode
                themeMode: this.darkTheme == 0 ? 'light' : 'dark',
                // themeVariables - https://docs.reown.com/appkit/react/core/theming#themevariables
                themeVariables: {
                    "--w3m-font-family": "Lexend Deca, sans-serif",
                    "--w3m-border-radius-master": "0px",
                }
            });
            this.appKitModal = appKitModal;
        },
        // 链接
        async connect(chainId) {
            // useAppKit - https://docs.reown.com/appkit/vue/core/composables#useappkit
            // const { open, close } = useAppKit();
            this.appKitModal.setThemeMode(this.darkTheme == 0 ? 'light' : 'dark');
            // 获取账户信息
            const accountData = useAppKitAccount();
            const account = accountData.value;
            if (account.isConnected) {
                // 断开连接
                this.appKitModal.disconnect();
                this.appKitModal.resetWcConnection();
                // 重新创建配置
                this.createConfig(chainId);
            }
            // 调用open函数时选择模态视图
            this.appKitModal.open({ view: 'Connect' });
            // 循环检测获取账户信息
            let interval = setInterval(() => {
                // 获取账户信息
                const connectedAccountData = useAppKitAccount();
                const connectedAccount = connectedAccountData.value;
                if (connectedAccount.isConnected) {
                    console.log("WalletConnect Connected!")
                    this.$store.dispatch('walletAddressHandler', connectedAccount.address);
                    this.$emit('emitGetRegisterStatus', null);
                    clearInterval(interval);
                }
            }, 2000);
        },
        // 签名
        async sign(message) {
            // useAppKitProvider - https://docs.reown.com/appkit/vue/core/composables#useappkitprovider
            const { walletProvider } = useAppKitProvider('eip155');
            const account = useAppKitAccount();
            const network  = useAppKitNetwork();
            const provider = new Ethers.providers.Web3Provider(walletProvider, network.chainId);
            const signer = provider.getSigner(account.address);
            // Signing Messages - https://docs.ethers.org/v5/single-page/#/v5/getting-started/-%23-getting-started--signing
            // signer.signMessage - https://docs.ethers.org/v5/single-page/#/v5/api/signer/-%23-Signer-signMessage
            signer?.signMessage(message).then(signature => {
                // 签名成功
                this.$emit('signSuccess', signature);
            }).catch(err => {
                // Error returned when rejected
                console.error(err);
                this.$emit('signError', null);
                this.$store.dispatch('snackbarMessageHandler', "Signature failed!");
            });
        },
        // 签名
        async signTypedData(blockchain, tokenId) {
            // signTypedData: https://docs.ethers.org/v5/single-page/#/v5/api/signer/-%23-Signer-signTypedData
            // 当前交易的区块链
            this.currentBlockchain = this.blockchains.filter(b => b.blockchain == blockchain)[0];
            if(!this.currentBlockchain) {
                this.$store.dispatch('snackbarMessageHandler', "Invaild blockchain");
                this.$emit('signError', null);
                return;
            }
            // 创建 web3 对象
            let web3 = new Web3(new Web3.providers.HttpProvider(this.currentBlockchain.RPCUrl));
            // 本次交易所在的区块链ID，转为10进制
            let currentBlockchainChainId = Number(web3.utils.toBN(this.currentBlockchain.chainId).toString());
            // 查询账户
            const account = useAppKitAccount().value;
            // 判断未连接时调用链接方法
            if (!account.isConnected) {
                await this.connect(currentBlockchainChainId);
                this.$emit('signError', null);
                return;
            }
            // Ethers 签名
            let domain = {
                name: "untrading Shared Contract",
                version: "1",
                chainId: this.currentBlockchain.chainId,
                verifyingContract: this.currentBlockchain.unNFTContract,
                salt: "0x48cdc51538d5c18ef784aab525d9823a2119e55be5a1288a7a9c675f0f202bdd"
            };
            let types = { 
                Unwrap: [
                    { name: "tokenId", type: "uint256" }
                ]
            };
            let params = { tokenId: tokenId };
            const { walletProvider } = useAppKitProvider('eip155');
            const network = useAppKitNetwork().value;
            // 网络不一致
            if (network.chainId != currentBlockchainChainId) {
                console.log("Connected network: ", network.chainId);
                console.log("Target network", currentBlockchainChainId);
                console.log("Reown switch network...");
                // 切换到目标网络
                let targetNetwork = this.networks.filter(chain => chain.id == currentBlockchainChainId)[0];
                network.switchNetwork(targetNetwork);
                this.invaildNetworkDialog = true;
                this.$emit('signError', null);
                return;
            }
            let ethers = new Ethers.providers.Web3Provider(walletProvider, currentBlockchainChainId);
            // signer._signTypedData - https://docs.ethers.org/v5/single-page/#/v5/api/signer/-%23-Signer-signTypedData
            ethers.getSigner()._signTypedData(domain, types, params).then(signature => {
                // 签名成功
                this.$emit('signSuccess', signature);
                // console.log(signature);
            }).catch(err => {
                // 签名失败
                console.error(err);
                this.$emit('signError', null);
                this.$store.dispatch('snackbarMessageHandler', "Signature failed!");
            });
        },
        // 签名
        async signUnCryptoTypedData(cryptoWrapped, blockchain, to, tokenId) {
            // signTypedData: https://docs.ethers.org/v5/single-page/#/v5/api/signer/-%23-Signer-signTypedData
            // 当前交易的区块链
            this.currentBlockchain = this.blockchains.filter(b => b.blockchain == blockchain)[0];
            if(!this.currentBlockchain) {
                this.$store.dispatch('snackbarMessageHandler', "Invaild blockchain");
                this.$emit('signError', null);
                return;
            }
            // 创建 web3 对象
            let web3 = new Web3(new Web3.providers.HttpProvider(this.currentBlockchain.RPCUrl));
            // 本次交易所在的区块链ID，转为10进制
            let currentBlockchainChainId = Number(web3.utils.toBN(this.currentBlockchain.chainId).toString());
            // 查询账户
            const account = useAppKitAccount().value;
            // 判断未连接时调用链接方法
            if (!account.isConnected) {
                await this.connect(currentBlockchainChainId);
                this.$emit('signError', null);
                return;
            }
            // Ethers 签名
            let domain = {
                name: cryptoWrapped.name,
                version: "1",
                chainId: this.currentBlockchain.chainId,
                verifyingContract: cryptoWrapped.address,
                salt: "0xc25ebea6dd97ec30f15ce845010d7e9fee0398194f29ee544b5062c074544590"
            };
            let types = { 
                Unwrap: [
                    { name: "to", type: "address" },
                    { name: "tokenId", type: "uint256" }
                ]
            };
            let params = { to: to, tokenId: tokenId };
            const { walletProvider } = useAppKitProvider('eip155');
            const network = useAppKitNetwork().value;
            // 网络不一致
            if (network.chainId != currentBlockchainChainId) {
                console.log("Connected network: ", network.chainId);
                console.log("Target network", currentBlockchainChainId);
                console.log("Reown switch network...");
                // 切换到目标网络
                let targetNetwork = this.networks.filter(chain => chain.id == currentBlockchainChainId)[0];
                network.switchNetwork(targetNetwork);
                this.invaildNetworkDialog = true;
                this.$emit('signError', null);
                return;
            }
            let ethers = new Ethers.providers.Web3Provider(walletProvider, currentBlockchainChainId);
            // signer._signTypedData - https://docs.ethers.org/v5/single-page/#/v5/api/signer/-%23-Signer-signTypedData
            ethers.getSigner()._signTypedData(domain, types, params).then(signature => {
                // 签名成功
                this.$emit('signSuccess', signature);
                // console.log(signature);
            }).catch(err => {
                // 签名失败
                console.error(err);
                this.$emit('signError', null);
                this.$store.dispatch('snackbarMessageHandler', "Signature failed!");
            });
        },
        // 发送交易
        async sendTransaction(blockchain, from, to, data, value) {
            // 判断账户登录过期时间
            let res = await UserAPI.getExpiration();
            let expirationData = res.data;
            if(expirationData.success) {
                let expiration = expirationData.data;
                // 过期时间在1小时以内的，直接重新登录
                if(expiration - new Date().getTime() < 60 * 60 * 1000) {
                    this.$emit('transactionClose', false, null)
                    this.$store.commit("tokenHandler", null);
                    this.$router.push("/connectwallet?redirectUrl=" + window.location.href);
                    return;
                }
            }
            // 当前交易的区块链
            this.currentBlockchain = this.blockchains.filter(b => b.blockchain == blockchain)[0];
            if(!this.currentBlockchain) {
                this.$store.dispatch('snackbarMessageHandler', "Invaild blockchain");
                return;
            }
            // 创建 web3 对象
            let web3 = new Web3(new Web3.providers.HttpProvider(this.currentBlockchain.RPCUrl));
            // 本次交易所在的区块链ID
            let currentBlockchainChainId = Number(web3.utils.toBN(this.currentBlockchain.chainId).toString());
            // 查询账户
            const account = useAppKitAccount().value;
            // 判断未连接时调用链接方法
            if (!account.isConnected) {
                await this.connect(currentBlockchainChainId);
                this.$emit('transactionClose', false, null);
                return;
            }
            // 查询当前钱包链接的网络
            const { walletProvider } = useAppKitProvider('eip155');
            const network = useAppKitNetwork().value;
            // 网络不一致则停止交易，重新连接
            if (network.chainId != currentBlockchainChainId) {
                console.log("Connected network: ", network.chainId);
                console.log("Target network", currentBlockchainChainId);
                console.log("Reown switch network...");
                // 切换到目标网络
                let targetNetwork = this.networks.filter(chain => chain.id == currentBlockchainChainId)[0];
                network.switchNetwork(targetNetwork);
                this.invaildNetworkDialog = true;
                this.$emit('signError', null);
                return;
            }
            // 请求超时定时器
            let timeoutTimer = setTimeout(() => {
                this.$store.dispatch('snackbarMessageHandler', "Request timeout");
                this.$emit('transactionClose', false, null);
            }, 60000);
            console.log("Reown send transaction...");
            // 发送交易
            try {
                let ethers = new Ethers.providers.Web3Provider(walletProvider, currentBlockchainChainId);
                // signer.sendTransaction - https://docs.ethers.org/v5/single-page/#/v5/api/signer/-%23-Signer-sendTransaction
                let hashResult = await ethers.getSigner().sendTransaction({
                    chainId: currentBlockchainChainId,
                    from: from,
                    to: to,
                    data: data,
                    value: value
                });
                // 交易成功
                console.log(hashResult.hash);
                this.$emit('transactionClose', true, hashResult.hash)
                // 取消请求超时定时器
                clearTimeout(timeoutTimer);
            } catch (err) {
                console.error(err);
                this.$emit('transactionClose', false, null);
                // -32603: Gas 太小错误
                if(err.code == -32603) {
                    this.$store.dispatch('snackbarMessageHandler', "Transaction underpriced");
                } else {
                    this.$store.dispatch('snackbarMessageHandler', "User rejected the request.");
                }
                // 取消请求超时定时器
                clearTimeout(timeoutTimer);
            }
            console.log("Reown close transaction!");
        },
        // 批准
        async approve(blockchain, from, to, data, value) {
            // 判断账户登录过期时间
            let res = await UserAPI.getExpiration();
            let expirationData = res.data;
            if(expirationData.success) {
                let expiration = expirationData.data;
                // 过期时间在1小时以内的，直接重新登录
                if(expiration - new Date().getTime() < 60 * 60 * 1000) {
                    this.$emit('approveTransactionClose', false, null)
                    this.$store.commit("tokenHandler", null);
                    this.$router.push("/connectwallet?redirectUrl=" + window.location.href);
                    return;
                }
            }
            // 当前交易的区块链
            this.currentBlockchain = this.blockchains.filter(b => b.blockchain == blockchain)[0];
            if(!this.currentBlockchain) {
                this.$store.dispatch('snackbarMessageHandler', "Invaild blockchain");
                return;
            }
            // 创建 web3 对象
            let web3 = new Web3(new Web3.providers.HttpProvider(this.currentBlockchain.RPCUrl));
            // 本次交易所在的区块链ID
            let currentBlockchainChainId = Number(web3.utils.toBN(this.currentBlockchain.chainId).toString());
            // 查询账户
            // 查询账户
            const account = useAppKitAccount().value;
            // 判断未连接时调用链接方法
            if (!account.isConnected) {
                await this.connect(currentBlockchainChainId);
                this.$emit('transactionClose', false, null);
                return;
            }
            // 查询当前钱包链接的网络
            const { walletProvider } = useAppKitProvider('eip155');
            const network = useAppKitNetwork().value;
            // 网络不一致则停止交易，重新连接
            if (network.chainId != currentBlockchainChainId) {
                console.log("Connected network: ", network.chainId);
                console.log("Target network", currentBlockchainChainId);
                console.log("Reown switch network...");
                // 切换到目标网络
                let targetNetwork = this.networks.filter(chain => chain.id == currentBlockchainChainId)[0];
                network.switchNetwork(targetNetwork);
                this.invaildNetworkDialog = true;
                this.$emit('signError', null);
                return;
            }
            // 请求超时定时器
            let timeoutTimer = setTimeout(() => {
                this.$store.dispatch('snackbarMessageHandler', "Request timeout");
                this.$emit('approveTransactionClose', false, null)
            }, 60000);
            console.log("Reown send transaction...");
            // 发送交易
            try {
                let ethers = new Ethers.providers.Web3Provider(walletProvider, currentBlockchainChainId);
                let hashResult = await ethers.getSigner().sendTransaction({
                    chainId: currentBlockchainChainId,
                    from: from,
                    to: to,
                    data: data,
                    value: value
                });
                // 交易成功
                console.log(hashResult.hash);
                this.$emit('approveTransactionClose', true, hashResult.hash)
                // 取消请求超时定时器
                clearTimeout(timeoutTimer);
            } catch (err) {
                console.error(err);
                this.$emit('approveTransactionClose', false, null)
                // -32603: Gas 太小错误
                if(err.code == -32603) {
                    this.$store.dispatch('snackbarMessageHandler', "Transaction underpriced");
                } else {
                    this.$store.dispatch('snackbarMessageHandler', "User rejected the request.");
                }
                // 取消请求超时定时器
                clearTimeout(timeoutTimer);
            }
            console.log("Reown close transaction!");
        },
        // 关闭无效网络弹窗
        closeInvaildNetworkDialog() {
            this.invaildNetworkDialog = false;
        },
    }
}
</script>
<style scoped>
:deep(.v-echarts-dialog){
    width: auto;
}
</style>