<template>
	<div :class="responsive != 'pc' ? 'table-container' : null">
        <h2 class="body-text-btn text-grey04">UN Token Vesting Schedule</h2>
        <table class="bg-f2f mt-5 w-100" :class="responsive != 'pc' ? 'table-min-width' : null" width="100%">
            <thead>
                <tr>
                    <th class="text-left form-text" style="width: 100px;">Allocation</th>
                    <th class="text-right form-text pr-4" style="width: 60px;">%</th>
                    <th class="text-center form-text" style="width: 100px;">Cliff<br/>(months)</th>
                    <th class="text-center form-text pr-4" style="width: 150px;">Vested<br/>(months) - post TGE</th>
                    <th class="text-left form-text">Vesting Schedule</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>KOL Round</td>
                    <td class="text-right pr-4">2.50%</td>
                    <td class="text-center">3</td>
                    <td class="text-center">12</td>
                    <td>10% of tokens unlocked at launch. The remaining 90% are released continuously through smart contract streaming over 12 months, following a 3-month cliff.</td>
                </tr>
                <tr>
                    <td>Strategic Round</td>
                    <td class="text-right pr-4">6.00%</td>
                    <td class="text-center">3</td>
                    <td class="text-center">15</td>
                    <td>5% of tokens unlocked at launch. The remaining 95% are released continuously through smart contract streaming over 15 months, following a 3-month cliff.</td>
                </tr>
                <tr>
                    <td>IDO</td>
                    <td class="text-right pr-4">3.00%</td>
                    <td class="text-center">3</td>
                    <td class="text-center">12</td>
                    <td>8.33% of tokens unlocked at launch. The remaining 91.67% are released continuously through smart contract streaming over 12 months, following a 3-month cliff.</td>
                </tr>
                <tr>
                    <td>Ecosystem & Rewards</td>
                    <td class="text-right pr-4">25.00%</td>
                    <td class="text-center">12</td>
                    <td class="text-center">60</td>
                    <td>10% of tokens unlocked at launch. The remaining 90% are released continuously through smart contract streaming over 60 months, following a 12-month cliff.</td>
                </tr>
                <tr>
                    <td>Early contributors</td>
                    <td class="text-right pr-4">6.00%</td>
                    <td class="text-center">3</td>
                    <td class="text-center">48</td>
                    <td>5% of tokens unlocked at launch. The remaining 95% are released continuously through smart contract streaming over 48 months, following a 3-month cliff.</td>
                </tr>
                <tr>
                    <td>Marketing</td>
                    <td class="text-right pr-4">10.00%</td>
                    <td class="text-center">6</td>
                    <td class="text-center">36</td>
                    <td>0% of tokens unlocked at launch. 100% are released continuously through smart contract streaming over 36 months, following a 6-month cliff.</td>
                </tr>
                <tr>
                    <td>Liquidity</td>
                    <td class="text-right pr-4">8.50%</td>
                    <td class="text-center">6</td>
                    <td class="text-center">24</td>
                    <td>8% of tokens unlocked at launch. The remaining 92% are released continuously through smart contract streaming over 24 months, following a 6-month cliff.</td>
                </tr>
                <tr>
                    <td>Lead MM</td>
                    <td class="text-right pr-4">1.50%</td>
                    <td class="text-center">0</td>
                    <td class="text-center">0</td>
                    <td>Market Making liquidity tokens exercisable after 18-month Service Agreement completion. Daily sales capped at 5% of trailing 7-day trading volume.</td>
                </tr>
                <tr>
                    <td>Team</td>
                    <td class="text-right pr-4">16.00%</td>
                    <td class="text-center">12</td>
                    <td class="text-center">36</td>
                    <td>5% of tokens unlocked at launch. The remaining 95% are eligible for periodic unlocks over 60 months based on KPI achievement, following a 12-month cliff.</td>
                </tr>
                <tr>
                    <td>Advisors</td>
                    <td class="text-right pr-4">2.50%</td>
                    <td class="text-center">6</td>
                    <td class="text-center">60</td>
                    <td>0% of tokens unlocked at launch. 100% are released continuously through smart contract streaming over 60 months, following a 6-month cliff.</td>
                </tr>
                <tr>
                    <td>Lead Advisor</td>
                    <td class="text-right pr-4">1.50%</td>
                    <td class="text-center">0</td>
                    <td class="text-center">18</td>
                    <td>10% of tokens unlocked at launch. The remaining 90% are released continuously through smart contract streaming over 18 months.</td>
                </tr>
                <tr>
                    <td>Treasury</td>
                    <td class="text-right pr-4">17.50%</td>
                    <td class="text-center">12</td>
                    <td class="text-center">60</td>
                    <td>0% of tokens unlocked at launch. 100% are released continuously through smart contract streaming over 48 months, following a 12-month cliff.</td>
                </tr>
            </tbody>
        </table>
	</div>
</template>
<script>
import { mapGetters } from "vuex";
export default {
    data(){
        return {

        }
    },
    components: {  },
    created(){

    },
    mounted(){

    },
    computed: {
        ...mapGetters(['responsive'])
    },
    watch:{

    },
    methods: {

    }
}
</script>
<style scoped>
.table-container {
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}

table { 
	width: 100%; 
	border-collapse: collapse; 
    
}

.table-min-width {
    min-width: 800px;
}

tr th {
	padding: 2px 0px; 
	border-bottom: 2px solid rgb(var(--v-theme-black01)); 
	text-align: left; 
}

td { 
	padding: 2px; 
	border-bottom: 1px solid #9e9e9e; 
	text-align: left; 
}

[data-toggle="toggle"] {
	display: none;
}
</style>