<template>
	<div class="r-p-x-6">
       <v-row no-gutters justify="start" align="center"> 
        <v-col cols="1" v-for="icon in productIcons" :key="icon.title">
            <router-link :to="icon.link" class="del-underline">
                <v-img contain :src="icon.coin" width="24" height="24" class="svg-icon"></v-img>
            </router-link>
        </v-col>
    </v-row>
	</div>
</template>
<script>
import { mapGetters } from "vuex";
export default {
    data(){
        return {
            icons: [
                {
                title: "unBTC",
                coin: 'https://imagedelivery.net/P4z5C24T1A4q673nx_eYTw/0662187f-3c66-49fd-ee98-7d4fdf0ab300/public',
                link:'/uncryptos?product=unBTC'
                },{
                title: "unETH",
                coin: 'https://imagedelivery.net/P4z5C24T1A4q673nx_eYTw/eb8f9207-75c9-400d-3499-543091c8e300/public',
                link:'/uncryptos?product=unETH'
                },{
                title: "unLINK",
                coin: 'https://imagedelivery.net/P4z5C24T1A4q673nx_eYTw/2ade7842-0e06-49ce-f85a-1492655bc600/public',
                link:'/uncryptos?product=unLINK'
                },{
                title: "unSOL",
                coin: 'https://imagedelivery.net/P4z5C24T1A4q673nx_eYTw/2cc2a24e-50d8-4945-d1b4-736144fc9100/public',
                link:'/uncryptos?product=unSOL'
                },{
                title: "unUNI",
                coin: 'https://imagedelivery.net/P4z5C24T1A4q673nx_eYTw/cf3dd5ef-2391-4a2b-988b-2d5709ddaa00/public',
                link:'/uncryptos?product=unUNI'
                },{
                title: "UN²",
                coin: 'https://imagedelivery.net/P4z5C24T1A4q673nx_eYTw/29d9f665-773d-4349-893b-769528b83700/public',
                link:'/uncryptos?product=UN%C2%B2'
                }
            ]
        }
    },
    components: {  },
    created(){

    },
    mounted(){

    },
    computed: {
        ...mapGetters(["darkTheme"]),

        productIcons() {
            // 判断是否为生产环境
            if (this.$config.env === 'prod') {
                return this.icons.filter(icon => icon.title !== 'unSOL');  // 例如：过滤掉 title 为 'un' 的项目
            } else {
                return this.icons;  // 非生产环境，返回所有数据
            }
        }
    },
    watch:{

    },
    methods: {

    }
}
</script>
<style scoped>
.svg-icon {
    filter: #9e9e9e; /* 调整颜色 */
  }
</style>