<template>
	<div class="layoutContent lexend-deca">
        <div class="Grid_root__iRfoa grid-style-1 r-p-b-16">
            <div class="Grid_a__vY7M8">
                <h2 class="typography_h1 text-quaternary">
                    Customize Your Token's DNA
                </h2>
            </div>
            <div class="Grid_b___vQi_ d-flex align-end text-tertiary r-m-t-16">
                Every token on Untrading has unique parameters that shape its community and rewards structure. These aren't just settings—they're the building blocks of your digital kingdom.
            </div>
        </div>
        <div style="background:rgb(var(--v-theme-bg-cfd));" role="none" aria-orientation="horizontal" data-orientation="horizontal" class="Separator_root__dpDqU mt-12"></div>
        <div class="page_bentoGrid__EhGwY Grid_root__iRfoa grid-style-2">
            <div class="page_bentoGridA__KQ2Vv Grid_a__vY7M8">
                <div class="ga-2 d-flex flex-column">
                    <h3 class="fs-21-bold text-quaternary">Future Rewards Flow</h3>
                    <div class="Spacer_root__uoSvA" style="--height:4px"></div>
                    <p class="fs-17 text-tertiary">Define how profits flow back to previous owners. Higher ratios create stronger community bonds, while lower ratios maximize immediate gains.</p>
                    <div class="Spacer_root__uoSvA" style="--height: 64px;"></div>
                    <div aria-hidden="true" class="ProjectOverview_root___sg3k">
                        <div class="GlassContainer_outer__e6vy_ utils_gradientBorder__9rE2_">
                            <div class="ProjectOverview_inner__hnVSN GlassContainer_inner__BdNcZ utils_gradientBorder__9rE2_">
                                <img alt="" data-nosnippet="true" data-loaded="true" loading="lazy" width="3200" height="1620" decoding="async" data-nimg="1" class="inert  page_trackingHeroImage Image_root" v-show="this.darkTheme == 1" src="https://imagedelivery.net/P4z5C24T1A4q673nx_eYTw/b51a6816-df04-4ebb-5d72-3fc330323500/public">
                                <img alt="" data-nosnippet="true" data-loaded="true" loading="lazy" width="3200" height="1620" decoding="async" data-nimg="1" class="inert  page_trackingHeroImage Image_root" v-show="this.darkTheme == 0" src="https://imagedelivery.net/P4z5C24T1A4q673nx_eYTw/ce09b58e-3a5b-48e7-5620-b0de7b794c00/public">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="page_bentoGridB__uS8Oy Grid_b___vQi_">
                <div class="ga-2 d-flex flex-column">
                    <h3 class="fs-21-bold text-quaternary">Generational Reach</h3>
                    <div class="Spacer_root__uoSvA" style="--height:4px"></div>
                    <p class="fs-17 text-tertiary">Set how many previous owners receive Future Rewards. Like a family tree, each generation participates in the token's ongoing success.</p>
                    <div class="Spacer_root__uoSvA" style="--height: 64px;"></div>
                    <div aria-hidden="true" class="ProjectOverview_root___sg3k">
                        <div class="GlassContainer_outer__e6vy_ utils_gradientBorder__9rE2_">
                            <div class="ProjectOverview_inner__hnVSN GlassContainer_inner__BdNcZ utils_gradientBorder__9rE2_">
                                <img alt="" data-nosnippet="true" data-loaded="true" loading="lazy" width="3200" height="1620" decoding="async" data-nimg="1" class="inert page_trackingHeroImage Image_root" v-show="this.darkTheme == 1" style="color:transparent" src="https://imagedelivery.net/P4z5C24T1A4q673nx_eYTw/a9254234-74e9-4c29-c463-60588cbe7600/public">
                                <img alt="" data-nosnippet="true" data-loaded="true" loading="lazy" width="3200" height="1620" decoding="async" data-nimg="1" class="inert page_trackingHeroImage Image_root" v-show="this.darkTheme == 0" style="color:transparent" src="https://imagedelivery.net/P4z5C24T1A4q673nx_eYTw/0d96ad27-dc54-4d91-c3ee-000b80a83200/public">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="Grid_root__iRfoa grid-style-3">
            <div class="page_bentoGridA__KQ2Vv Grid_a__vY7M8">
                <div class="ga-2 d-flex flex-column">
                    <h3 class="fs-21-bold text-quaternary">Originators' Share</h3>
                    <div class="Spacer_root__uoSvA" style="--height:4px"></div>
                    <p class="fs-17 text-tertiary">Balance rewards between creators and community. A portion goes to Untrading and token originators, ensuring sustainable platform growth.</p>
                    <div class="Spacer_root__uoSvA" style="--height: 64px;"></div>
                    <div aria-hidden="true" class="ProjectOverview_root___sg3k">
                        <div class="GlassContainer_outer__e6vy_ utils_gradientBorder__9rE2_">
                            <div class="ProjectOverview_inner__hnVSN GlassContainer_inner__BdNcZ utils_gradientBorder__9rE2_">
                                <img alt="" data-nosnippet="true" data-loaded="true" loading="lazy" width="704" height="766" decoding="async" data-nimg="1" class="inert Image_root" v-show="this.darkTheme == 1" style="color:transparent" src="https://imagedelivery.net/P4z5C24T1A4q673nx_eYTw/ce64b5eb-6a27-4da5-c59e-41b357390300/public">
                                <img alt="" data-nosnippet="true" data-loaded="true" loading="lazy" width="716" height="811" decoding="async" data-nimg="1" class="inert Image_root" v-show="this.darkTheme == 0" style="color:transparent" src="https://imagedelivery.net/P4z5C24T1A4q673nx_eYTw/860f4ca5-082a-45e7-b487-52cd7ae4b000/public">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="page_bentoGridB__uS8Oy Grid_b___vQi_">
                <div class="ga-2 d-flex flex-column">
                    <h3 class="fs-21-bold text-quaternary">Kingdom Treasury</h3>
                    <div class="Spacer_root__uoSvA" style="--height:4px"></div>
                    <p class="fs-17 text-tertiary">Every transaction contributes to your community's treasury, funding future development and community initiatives. True decentralized governance in action.</p>
                    <div class="Spacer_root__uoSvA" style="--height: 64px;"></div>
                    <div aria-hidden="true" class="ProjectOverview_root___sg3k">
                        <div class="GlassContainer_outer__e6vy_ utils_gradientBorder__9rE2_">
                            <div class="ProjectOverview_inner__hnVSN GlassContainer_inner__BdNcZ utils_gradientBorder__9rE2_">
                                <img alt="" data-nosnippet="true" data-loaded="true" loading="lazy" width="3200" height="1620" decoding="async" data-nimg="1" class="inert  page_trackingHeroImage Image_root" v-show="this.darkTheme == 1" style="color:transparent" src="https://imagedelivery.net/P4z5C24T1A4q673nx_eYTw/b180c52a-77ff-4be6-2224-51d7bd4a8300/public">
                                <img alt="" data-nosnippet="true" data-loaded="true" loading="lazy" width="3200" height="1620" decoding="async" data-nimg="1" class="inert  page_trackingHeroImage Image_root" v-show="this.darkTheme == 0" style="color:transparent" src="https://imagedelivery.net/P4z5C24T1A4q673nx_eYTw/734f3f8f-9a93-4a00-43fa-0e115026ed00/public">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div style="background:rgb(var(--v-theme-bg-cfd));" role="none" aria-orientation="horizontal" data-orientation="horizontal" class="Separator_root__dpDqU mb-12"></div>
        <div class="Grid_root__iRfoa ga-10 grid-style-4">
            <div class="Grid_a__vY7M8">
                <div class="ga-2 d-flex flex-column">
                    <h4 class="fs-14 line-height-24 text-secondary">Reward Balance</h4>
                    <span class="fs-14 text-tertiary">
                        Find your sweet spot between immediate and future gains.
                    </span>
                </div>
            </div>
            <div class="Grid_b___vQi_">
                <div class="ga-2 d-flex flex-column">
                    <h4 class="fs-14 line-height-24 text-secondary">Growth & Balance</h4>
                    <span class="fs-14 text-tertiary">
                        Each generation participates in success.
                    </span>
                </div>
            </div>
            <div class="Grid_c__Tv5Qw">
                <div class="ga-2 d-flex flex-column">
                    <h4 class="fs-14 line-height-24 text-secondary">Depth Control</h4>
                    <span class="fs-14 text-tertiary">
                        Balance reward distribution across time.
                    </span>
                </div>
            </div>
            <div class="Grid_d__WN1qv">
                <div class="ga-2 d-flex flex-column">
                    <h4 class="fs-14 line-height-24 text-secondary">Aligned Interests</h4>
                    <span class="fs-14 text-tertiary">
                        What's good for one is good for all.
                    </span>
                </div>
            </div>
        </div>
        <div class="mt-16">
            <a href="https://untrading.medium.com/mastering-untradings-profit-expectancy-76166f6abcfb" target="_blank" type="button" class="button_root button_variant-secondary button_size-default button_variant link_root" rel="noopener">
                <span class="text-tertiary" style="padding: 0px;">Explore PE Levels</span><v-icon class="text-black01">mdi mdi-chevron-right</v-icon>
            </a>
        </div>
	</div>
</template>
<script>
import { mapGetters } from "vuex";
export default {
    data(){
        return {
        }
    },
    components: {  },
    created(){

    },
    mounted(){

    },
    computed: {
        ...mapGetters(["darkTheme"])
    },
    watch:{

    },
    methods: {
    }
}
</script>
<style scoped>

</style>