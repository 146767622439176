<template>
    <div>
        <v-navigation-drawer :model-value="drawer" :rail="rail" rail-width="58" width="230" location="left" color="bg-secondary">
            <v-list-item class="mt-3">
                <div v-if="$route.meta.app" class="pointer">
                    <div v-show="this.darkTheme == 0">
                        <Logo :type="$route.meta.app" :app="$route.meta.app" color="light" responsive="pc" :size="$route.meta.app == 'unNFTs' ? 90 : 120"></Logo>
                    </div>
                    <div v-show="this.darkTheme == 1" >
                        <Logo :type="$route.meta.app" :app="$route.meta.app" color="dark" responsive="pc" :size="$route.meta.app == 'unNFTs' ? 90 : 120"></Logo>
                    </div>
                </div>
            </v-list-item>
            <v-divider class="my-2"></v-divider>
            <!-- <v-list-item>
                <template v-slot:prepend>
                    <v-icon class="pointer" @click.stop="rail = !rail">mdi mdi-menu-close</v-icon>
                </template>
                <template v-slot:append>
                    <v-icon class="pointer" @click.stop="rail = true">mdi mdi-chevron-left</v-icon>
                </template>
            </v-list-item>
            <v-divider></v-divider> -->
            <v-list>
                <v-list-item>
                    <a href="/unnft/mint" class="del-underline">
                        <v-list-item-title v-if="rail" class="gradient-underline-hover">
                            <v-icon icon="mdi:mdi-image-plus-outline"></v-icon>
                        </v-list-item-title>
                        <v-btn v-if="!rail" width="110" height="40" rounded="0" elevation="4" class="ml-n1 btn">
                            <template v-slot:prepend>
                                <v-icon icon="mdi:mdi-image-plus-outline" ></v-icon>
                            </template>
                            <span class="text-none">Create</span>
                        </v-btn>
                    </a>
                </v-list-item>
                <v-list-item v-for="(menu, index) in nftMenus" :key="index" :value="index">
                    <template v-slot:default="{ isActive }">
                        <router-link :to="menu.to" class="del-underline">
                            <v-list-item-title class="gradient-underline-hover">
                                <v-icon :icon="menu.icon" :class="isActive ? 'text-primary' : 'text-primaryGrey'"></v-icon>
                                <a class="ml-3 gradient-text-hover" :class="isActive ? 'text-primary' : 'text-primaryGrey'">{{ menu.title }}</a>
                            </v-list-item-title>
                        </router-link>
                    </template>
                </v-list-item>
            </v-list>
            <!-- 导航栏底部 -->
            <template v-slot:append>
                <v-list-item>
                    <v-list-item-title>
                        <Installation color="primaryGrey" size="22"></Installation>
                    </v-list-item-title>
                </v-list-item>
                <v-list-item>
                    <v-list-item-title>
                        <!-- Discord -->
                        <a href="https://discord.gg/D5rZdhzVWh" target="_blank" class="del-underline" aria-label="Discord">
                            <v-btn icon variant="text" size="24">
                                <v-menu v-model="discordMenu" transition="slide-y-transition" :offset="10" location="top" open-on-hover contained>
                                    <template v-slot:activator="{ props }" >
                                        <div v-bind="props" >
                                            <v-icon class="icon" color="primaryGrey" size="24">icon-dis</v-icon>
                                        </div>
                                    </template>
                                    <v-card class="pa-3 text-primaryGrey" rounded="0">
                                        <h6 class="body-p font-weight-thin text-none">We are online! How may I help you today? </h6>
                                    </v-card>
                                </v-menu>
                            </v-btn>
                        </a>
                    </v-list-item-title>
                </v-list-item>
                <v-list-item>
                    <v-list-item-title>
                        <!--举报作弊图标 -->
                        <v-btn icon variant="text" size="24" @click="reportCheatingDialog = true">
                            <v-menu v-model="reportCheatingMenu" transition="slide-y-transition" :offset="10" location="top" open-on-hover contained>
                                <template v-slot:activator="{ props }">
                                    <div v-bind="props">
                                        <v-icon class="icon" color="primaryGrey" size="24">icon-alert</v-icon>
                                    </div>
                                </template>
                                <v-card class="pa-3 text-primaryGrey" rounded="0">
                                    <h6 class="body-p font-weight-thin text-none">Report Cheating</h6>
                                </v-card>
                            </v-menu>
                        </v-btn>
                    </v-list-item-title>
                </v-list-item>
                <v-list-item>
                    <v-list-item-title>
                        <Theme color="primaryGrey" size="24"></Theme>
                    </v-list-item-title>
                </v-list-item>
                <v-divider></v-divider>
                <v-list-item>
                    <v-list-item-title class="mb-8">
                        <div class="gradient-underline-hover ml-5">
                            <a href="https://docs.untrading.org" target="_blank" class="ml-3 body-p-small text-primaryGrey gradient-text-hover">Docs</a>
                            <span class="mx-2 text-primaryGrey">·</span>
                            <a href="https://kingdoms.untrading.org" target="_blank" class="body-p-small text-primaryGrey gradient-text-hover">Kingdoms</a>
                        </div>
                        <div class="gradient-underline-hover ml-5">
                            <a href="https://docs.untrading.org/terms-of-service" target="_blank" class="ml-3 body-p-small text-primaryGrey gradient-text-hover">Terms</a>
                            <span class="mx-2 text-primaryGrey">·</span>
                            <a href="https://docs.untrading.org/privacy-policy" target="_blank" class="body-p-small text-primaryGrey gradient-text-hover">Privacy</a>
                        </div>
                        <div class="d-flex align-center mt-1">
                            <a href="https://untrading.org" class="pointer">
                                <!-- <Logo type="icon" app="untrading" size="24"></Logo>/ -->
                                <Logo type="full_icon_untrading" app="untrading" :color="darkTheme == 1 ? 'dark' : 'light'" size="130"></Logo>
                            </a>
                        </div>
                    </v-list-item-title>
                </v-list-item>
            </template>
        </v-navigation-drawer>
        <!-- 举报作弊弹窗 -->
        <ReportCheating :show="reportCheatingDialog" @watchReportCheatingDialog="watchReportCheatingDialog"></ReportCheating>
    </div>
</template>
<script>
import Logo from '@/components/common/Logo';
import Installation from '@/components/common/Installation';
import ReportCheating from '@/components/common/ReportCheating';
import Theme from '@/components/common/Theme';
import { mapGetters } from "vuex";
export default {
    props: {
        drawer: {
            type: Boolean,
            default: false
        },
    },
    data(){
        return {
            // 是否窄栏
            rail: false,
            // Discord 菜单
            discordMenu: false,
            // 举报作弊菜单
            reportCheatingMenu: false,
            // 举报作弊弹窗
            reportCheatingDialog: false,
            // NFT 菜单
            nftMenus: [
                { title: 'Discover', icon: 'mdi:mdi-bullseye', to: '/unnfts' },
                { title: 'Explore', icon: 'mdi:mdi-compass', to: '/market' },
                { title: 'Curated', icon: 'mdi:mdi-star', to: '/sets' },
                { title: 'Collections', icon: 'mdi:mdi-image-filter-black-white', to: '/collections' },
                { title: 'Import unNFT', icon: 'mdi:mdi-image-check-outline', to: '/unnft/import' },
                { title: 'Wrap NFT', icon: 'mdi:mdi-image-refresh-outline', to: '/unnft/wrap' },
                { title: 'Create Set', icon: 'mdi:mdi-image-multiple-outline', to: '/set/create' },
                { title: 'Create Collection', icon: 'mdi:mdi-file-multiple-outline', to: '/collection/create' },
            ],
        }
    },
    components: { Logo, Installation, ReportCheating, Theme },
    created(){

    },
    mounted(){

    },
    computed: {
        ...mapGetters(['darkTheme','responsive'])
    },
    watch:{

    },
    methods: {
        watchReportCheatingDialog(show) {
            this.reportCheatingDialog = show;
        },
    }
}
</script>
<style scoped>
/* 条目激活时显示主题色 */
/* .v-list-item--active {
    background: rgb(var(--v-theme-primary)) !important;
    color: rgb(var(--v-theme-primary)) !important;
} */
</style>