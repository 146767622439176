<template>
	<div>
        <div class="kol-glow-kingdom-content"></div>
        <v-row no-gutters justify="start" class="r-m-t-16">
            <v-col cols="12" sm="6" md="6" xxl="6" class="pa-0">
                <v-img cover :aspect-ratio="1" src="https://kingdoms.untrading.org/media/posts/11/responsive/Golden-Crown-Alex-Style-2xl.webp"></v-img>
            </v-col>
            <v-col cols="12" sm="6" md="6" xxl="6" class="px-0 pl-16 r-p-l-0 p-p-l-8 m-t-16">
                <div class="ga-2 d-flex flex-column">
                    <h3 class="fs-21-bold text-quaternary">Tangible Community</h3>
                    <div class="Spacer_root__uoSvA" style="--height:4px"></div>
                    <p class="fs-17 text-tertiary lexend-deca">Create a digital nation where your influence translates to real, lasting value. Shape the future of finance and governance in your own Network State.</p>
                </div>
                <div class="ga-2 d-flex flex-column mt-16">
                    <h3 class="fs-21-bold text-quaternary">From Follower to Citizen</h3>
                    <div class="Spacer_root__uoSvA" style="--height:4px"></div>
                    <p class="fs-17 text-tertiary lexend-deca">Transform your social media followers into active citizens of your digital kingdom. Engage them in collaborative wealth creation and shared governance. </p>
                </div>
                <div class="ga-2 d-flex flex-column mt-16">
                    <h3 class="fs-21-bold text-quaternary">Your Community, Your Rules</h3>
                    <div class="Spacer_root__uoSvA" style="--height:4px"></div>
                    <p class="fs-17 text-tertiary lexend-deca">Set custom rules for asset management and profit-sharing within your kingdom. Unlike traditional platforms, you control the rewards and governance structure.</p>
                </div>
            </v-col>         
        </v-row>
        <v-row no-gutters class="mt-100">
            <v-col cols="12" sm="6" md="6" xxl="6" class="pl-4 r-p-l-0 r-text-right px-0">
                <div class="ga-2 d-flex flex-column">
                    <h3 class="fs-21-bold text-quaternary">Lasting Rewards for Leaders</h3>
                    <div class="Spacer_root__uoSvA" style="--height:4px"></div>
                     <p class="fs-17 text-tertiary">Benefit from Originators' Rewards and Future Rewards, ensuring you profit from your community's long-term success. Your influence becomes a sustainable, growing asset.</p>
                </div>
                <div class="ga-2 d-flex flex-column mt-16">
                    <h3 class="fs-21-bold text-quaternary">Collaborative Wealth Building</h3>
                    <div class="Spacer_root__uoSvA" style="--height:4px"></div>
                     <p class="fs-17 text-tertiary">Work together with your community members to maximize returns and share in the prosperity. As your kingdom grows, so do the rewards for all participants.</p>
                </div>
                <div class="ga-2 d-flex flex-column mt-16">
                    <h3 class="fs-21-bold text-quaternary">Bridging Digital and Physical Realms</h3>
                    <div class="Spacer_root__uoSvA" style="--height:4px"></div>
                    <p class="fs-17 text-tertiary">Start in the digital space and potentially expand into physical meetups or shared spaces. Build a true Network State that transcends traditional boundaries. </p>
                </div>
                <div class="mt-16">
                    <a href="/kols" target="_blank" type="button" class="button_root button_variant-secondary button_size-default button_variant link_root" rel="noopener">
                        <span class="gjcrSl" style="padding: 0px;">More</span><v-icon class="text-white">mdi mdi-chevron-right</v-icon>
                    </a>
                </div>
            </v-col>
            <v-col cols="12" sm="6" md="6" xxl="6" class="px-0 pl-16 r-p-l-0 order-first-mobile p-p-l-8 m-b-16">
                <v-img cover :aspect-ratio="1" src="https://imagedelivery.net/P4z5C24T1A4q673nx_eYTw/098efd76-4aba-4974-dca9-ba52fe97ea00/public"></v-img>
            </v-col>
        </v-row>
	</div>
</template>
<script>
export default {
    data(){
        return {

        }
    },
    components: {  },
    created(){

    },
    mounted(){

    },
    computed: {

    },
    watch:{

    },
    methods: {

    }
}
</script>
<style scoped>
/* Default order for all items */
.order-first-mobile {
    order: 2;
  }
  
  /* On mobile devices, make this item first */
  @media only screen and (max-width: 600px) {
    .order-first-mobile {
      order: -1;
    }
  }
</style>