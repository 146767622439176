<template>
    <div>
        <v-container class="align-center bg-homeBg content r-p-x-0"  id="fadeIn" :style="`margin-bottom: ${footerHeight}px`">
            <v-container class="mx-0-auto r-p-x-0 glow-effect">
                <div class="extra-glow-header-right"></div>
                <!-- <div class="d-flex align-center justify-center pt-100">
                    <Hero></Hero>
                </div> -->
                <v-row no-gutters justify="center" align="start" class="r-p-y-8 r-m-b-150 mt-8">
                    <v-col cols="12" sm="12" md="12" xl="12" xxl="12" class="px-0">
                        <CryptoScreenshot></CryptoScreenshot>
                        <!-- <v-row no-gutters justify="start" align="center">
                            <v-col cols="12" sm="12" lg="3" xl="3" xxl="3" class="position-relative marketTop">
                                <Market></Market>
                            </v-col>
                        </v-row> -->
                    </v-col>
                </v-row>
                <!-- <div class="d-flex align-center justify-center home-85vh show-mobile">
                    <Hero></Hero>
                </div> -->
                <!-- <div class="Hero_heroImageContainer__E6x9O bleed_root">
                    <div class="HeroIllustration_root">
                        <div class="HeroIllustration_perspective__JlJ9c" aria-hidden="true">
                            <div class="HeroIllustration_3d__JMg8B HeroIllustration_base">
                                <div class="HeroIllustration_inbox__yftxS" style="opacity: 1; filter: blur(0px); transform: translateZ(0px) translateX(0px); will-change: transform;">
                                    <img alt="" data-nosnippet="true" data-loaded="true"  loading="lazy" width="3200" height="1620" decoding="async" data-nimg="1" class="inert" v-show="this.darkTheme == 1"  src="https://imagedelivery.net/P4z5C24T1A4q673nx_eYTw/31b6b41c-4a57-4934-f68c-1d9a06a81c00/public">
                                    <img alt="" data-nosnippet="true" data-loaded="true" loading="lazy" width="3200" height="1620" decoding="async" data-nimg="1" class="inert" v-show="this.darkTheme == 0" style="color:transparent" src="https://imagedelivery.net/P4z5C24T1A4q673nx_eYTw/f01f3bdb-d006-47ab-761d-450e73d7bd00/public"> 
                                </div>
                            </div>
                        </div>
                    </div>
                    </div> -->
            </v-container>
            <v-container class="r-p-x-0">
                <v-row no-gutters justify="center" align="center" class="py-250 r-p-y-150 bg-black">
                    <Partner></Partner>
                </v-row>
                <v-row no-gutters class="py-250 r-p-y-150 bg-homeBg" justify="center" align="center">
                    <div class="layoutContent lexend-deca">
                        <div class="iDuRqP jDgTiB">
                            <div class="flex_root align-center justify-center flex-column" style="gap: 64px; opacity: 1; filter: blur(0px); transform: translateY(0%); will-change: transform;">
                                <a href="https://testnet.untrading.org/uncryptos" class="del-underline">
                                    <button class="btn button_root button_size-default button_variant lexend-deca">Start Untrading</button>
                                </a>
                                <a href="https://demo.untrading.org/" type="button" class="button_root button_variant-secondary button_size-default button_variant link_root" rel="noopener">
                                    <span class="text-tertiary" style="padding: 0px;">Demo</span><v-icon class="text-black01">mdi mdi-chevron-right</v-icon>
                                </a>
                                <a href="/features" type="button" class="button_root button_variant-secondary button_size-default button_variant link_root" rel="noopener">
                                    <span class="text-tertiary" style="padding: 0px;">Explore Features</span><v-icon class="text-black01">mdi mdi-chevron-right</v-icon>
                                </a>
                                
                                <!-- <a href="/features" type="button" class="button_root button_variant-ghost button_size-default button_variant link_root" rel="noopener">
                                    <span class="gjcrSl lexend-deca" style="padding: 0px;">Explore Features</span><v-icon>mdi mdi-chevron-right</v-icon>
                                </a> -->
                            </div>
                        </div>
                    </div>
                </v-row>
                <v-divider></v-divider>
            </v-container>
        </v-container>
        <v-container class="footer bg-homeBg" ref="footer">
            <v-row no-gutters justify="center">
                <v-col cols="12" sm="12" md="8" class="px-0">
                    <Footer></Footer>
                </v-col>
            </v-row>
            <v-divider></v-divider>
            <v-row no-gutters justify="center" class="bg-homeBg" v-if="responsive == 'pc'">
                <v-col cols="12" sm="12" md="8" xl="8" class="px-0">
                    <v-row no-gutters justify="start" align="center">
                        <v-col cols="12" sm="12" md="6" xl="6" xxl="6" class="px-0 my-2 d-flex justify-end align-center">
                            <v-row no-gutters>
                                <div class="gradient-underline-hover footer-14 r-m-t-8">
                                    <a href="https://docs.untrading.org/terms-of-service" target="_blank" class="text-primaryGrey del-underline">
                                    <span class="gradient-text-hover">Terms of Service</span>
                                    </a>
                                </div>
                                <div class="gradient-underline-hover footer-14 r-m-t-8 px-16 r-p-x-0">
                                    <a href="https://docs.untrading.org/privacy-policy" target="_blank" class="text-primaryGrey del-underline">
                                    <span class="gradient-text-hover">Privacy Policy</span>
                                    </a>
                                </div>
                                <div class="gradient-underline-hover footer-14 r-m-t-8">
                                    <a href="https://drive.google.com/drive/folders/11nDyyHIT7q8xBmoMrOa4Y7IwhNpzPBPj?usp=sharing" target="_blank" class="text-primaryGrey del-underline">
                                    <span class="gradient-text-hover">Media kit</span>
                                    </a>
                                </div>
                            </v-row>
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>
<script>
    import { mapGetters } from "vuex";
    import CryptoScreenshot from './components/CryptoScreenshot.vue';
    import Footer from './components/Footer.vue';
    import Hero from './components/Hero.vue';
    import Market from './components//Market.vue';
    import Partner from './components//Partner.vue';
    export default {
        data(){
            return {
                scroll: 0,
                footerHeight: 0,
            }
        },
        components: {CryptoScreenshot, Footer, Hero, Market, Partner},
        created(){
          
        },
        mounted() {
            this.calculateFooterHeight(); // 页面加载时计算 footer 高度
            window.addEventListener('resize', this.calculateFooterHeight); // 监听窗口大小变化
        },
        beforeDestroy() {
            window.removeEventListener('resize', this.calculateFooterHeight); // 移除事件监听
        },
        computed: {
                ...mapGetters(["darkTheme",'responsive'])
            },
        watch:{
    
        },
        methods: {
            calculateFooterHeight() {
                const footer = this.$refs.footer; // 访问组件的引用
                if (footer && footer.$el) { // 确保 footer 和 $el 都存在
                    this.footerHeight = footer.$el.offsetHeight; // 获取 footer 高度
                    console.log('Footer height:', this.footerHeight);
                } else {
                    console.warn('Footer reference or $el is null');
                }
            },
        },
    }
</script>
<style scoped>
    .content {
    flex: 1;
    /* 自适应填充剩余空间 */
    position: relative;
    z-index: 1;
    /* 确保内容在 footer 之上 */
    }
    .footer {
    position: fixed;
    /* 使用固定定位 */
    bottom: 0;
    /* 使 footer 固定在底部 */
    z-index: 0;
    /* 确保 footer 在内容之下 */
    }
    @-webkit-keyframes fadeIn {
    0% {
    opacity: 0;
    /*初始状态 透明度为0*/
    }
    50% {
    opacity: 0.3;
    /*中间状态 透明度为0*/
    }
    100% {
    opacity: 1;
    /*结尾状态 透明度为1*/
    }
    }
    #fadeIn {
    -webkit-animation-name: fadeIn;
    /*动画名称*/
    -webkit-animation-duration: 0.5s;
    /*动画持续时间*/
    -webkit-animation-iteration-count: 1;
    /*动画次数*/
    }
    /* Default order for all items */
    .order-first-mobile {
    order: 2;
    }
    /* On mobile devices, make this item first */
    @media only screen and (max-width: 600px) {
    .order-first-mobile {
    order: -1;
    }
    .mobile-text-center {
    text-align: center;
    }
    }
</style>