<template>
	<div>
        <div class="layoutContent">
            <div class="Grid_root__iRfoa grid-style-18">
                <div class="Grid_a__vY7M8">
                    <h2 class="typography_h1 lexend-deca text-quaternary">
                        Beyond Time & Sales: True Trading Transparency
                    </h2>
                </div>
                <div class="Grid_b___vQi_ d-flex align-end text-tertiary r-m-t-16 fs-17">
                    Traditional exchanges only show when and what. Untrading adds who, transforming basic transaction data into rich community insights. Every trade tells a story of who's building value in your token's ecosystem.
                </div>
            </div>
            <div class="Spacer_root__uoSvA" style="--height:48px"></div>
            <div class="bleed_root">
                <div class="Carousel_root__TqsO7 Carousel_variant-inset__GHiLJ Carousel_align-center__2bgqN" style="--Carousel-gap:8px">
                    <div ref="Carousel_scroll_container" class="Carousel_content__G17d_ utils_hideScrollbars__TzPqp" @scroll="updateButtonState()">
                        <div ref="Carousel_scroll_content" class="Carousel_inner__L4Uvf">
                            <div class="Carousel_item__k73Fv">
                                <div style="opacity: 1;">
                                        <v-hover v-slot="{ isHovering, props }">
                                            <v-card class="differentCard reset_reset-button" :class="{ 'on-hover': isHovering }" :elevation="isHovering ? 12 : 0" v-bind="props" rounded="0" >
                                                <v-img  src="https://imagedelivery.net/P4z5C24T1A4q673nx_eYTw/a5c229eb-1201-4d9f-092d-5c4260581000/public"  width="960" height="914" cover class="Image_root__UkRqc trans-image-2" v-show="this.darkTheme == 1" style="color: transparent; position: relative;"></v-img>
                                                <v-img  src="https://imagedelivery.net/P4z5C24T1A4q673nx_eYTw/a059f6e7-cc5a-44cc-d914-3334ca57ee00/public"  width="960" height="914" cover class="Image_root__UkRqc trans-image-2" v-show="this.darkTheme == 0" style="color: transparent; position: relative;"></v-img>
                                                <div class="text-container d-flex align-start justify-space-between">
                                                <p class="fs-21 text-quaternary marcellus-sc">
                                                    Beyond Time & Sales
                                                </p>
                                                <button class="iconButton button-root button_variant-tertiary button_size-default rounded-circle button_variant_icon" type="button">
                                                    <svg class="" width="16" height="16" viewBox="0 0 16 16" fill="#9c9da1" role="img" focusable="false" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" style="--icon-color: #9c9da1;"><path d="M8.75 4C8.75 3.58579 8.41421 3.25 8 3.25C7.58579 3.25 7.25 3.58579 7.25 4V7.25H4C3.58579 7.25 3.25 7.58579 3.25 8C3.25 8.41421 3.58579 8.75 4 8.75H7.25V12C7.25 12.4142 7.58579 12.75 8 12.75C8.41421 12.75 8.75 12.4142 8.75 12V8.75H12C12.4142 8.75 12.75 8.41421 12.75 8C12.75 7.58579 12.4142 7.25 12 7.25H8.75V4Z"></path></svg>
                                                </button>
                                                </div>
                                            </v-card>
                                            <v-dialog scrollable style="pointer-events: auto;opacity: 1;will-change: transform;" activator="parent" max-width="960">
                                                <template v-slot:default="{ isActive }">
                                                    <v-card rounded="0" elevation="0" class="bg-very-dark">
                                                        <v-card-title class="d-flex r-justify-end align-center r-p-x-4">
                                                            <button class="iconButton button-root button_variant-tertiary differentCard_close button_size-default rounded-circle button_variant_icon" type="button" @click="isActive.value = false">
                                                                <i class="mdi mdi-close"></i>
                                                            </button>
                                                        </v-card-title>
                                                        <v-card-text style="max-height: 1000px;">
                                                            <div style="min-width: 100%; display: table;">
                                                                <div class="hide-tablet Spacer_root__uoSvA" style="--height: -128px;"></div>
                                                                <div class="show-tablet Spacer_root__uoSvA" style="--height: -48px;"></div>
                                                                <div>
                                                                    <img alt="" data-nosnippet="true" data-loaded="true" loading="lazy" width="960" height="914" decoding="async" data-nimg="1" class="Image_root__UkRqc" v-show="this.darkTheme == 1" src="https://imagedelivery.net/P4z5C24T1A4q673nx_eYTw/a5c229eb-1201-4d9f-092d-5c4260581000/public" style="color: transparent;">
                                                                    <img alt="" data-nosnippet="true" data-loaded="true" loading="lazy" width="960" height="914" decoding="async" data-nimg="1" class="Image_root__UkRqc" v-show="this.darkTheme == 0" src="https://imagedelivery.net/P4z5C24T1A4q673nx_eYTw/a059f6e7-cc5a-44cc-d914-3334ca57ee00/public" style="color: transparent;">
                                                                </div>
                                                                <div class="hide-tablet Spacer_root__uoSvA" style="--height: -248px;"></div>
                                                                <div class="show-tablet Spacer_root__uoSvA" style="--height: -64px;"></div>
                                                                <div style="max-width: 720px; margin: 0px auto;">
                                                                    <div style="transform: none; transform-origin: 50% 50% 0px;">
                                                                        <div class="Spacer_root__uoSvA" style="--height: 40px;"></div>
                                                                        <div class="flex_root flex-column prose text-tertiary">
                                                                            <div class="flex_root flex-column prose text-tertiary">
                                                                                <p>
                                                                                    Every transaction's full story is visible and verifiable, setting a new standard for DeFi transparency.
                                                                                </p>
                                                                                <ol class="pl-8">
                                                                                    <li>Beyond Time & Sales: Not just when and at what price—see the complete trading history, including buyer and seller identities, building a rich tapestry of community interaction.</li>
                                                                                    <li>On-Chain Truth: Every detail is recorded on the blockchain, making it immutable and verifiable by anyone, anytime.</li>
                                                                                    <li>Community Knowledge: Make informed decisions by understanding the history and behavior patterns of potential trading partners.</li>
                                                                                </ol>
                                                                            </div>
                                                                        </div>
                                                                        <div class="WorkflowCard_bottomSpacer__ESEnP"></div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </v-card-text>
                                                    </v-card>
                                                </template>
                                            </v-dialog>
                                        </v-hover>
                                 </div>
                            </div>
                            <div class="Carousel_item__k73Fv">
                                <div style="opacity: 1;">
                                    <v-hover v-slot="{ isHovering, props }">
                                        <v-card class="differentCard reset_reset-button" color="black" :class="{ 'on-hover': isHovering }" :elevation="isHovering ? 12 : 0" v-bind="props" rounded="0" >
                                            <v-img  src="https://imagedelivery.net/P4z5C24T1A4q673nx_eYTw/465e48b4-06d5-4b47-f5d4-fe493041d500/public"  width="960" height="914" cover class="Image_root__UkRqc trans-image-2" v-show="this.darkTheme == 1" style="color: transparent; position: relative;"></v-img>
                                            <v-img  src="https://imagedelivery.net/P4z5C24T1A4q673nx_eYTw/978fb5cb-7333-4313-efee-0a0dca835a00/public"  width="960" height="914" cover class="Image_root__UkRqc trans-image-2" v-show="this.darkTheme == 0" style="color: transparent; position: relative;"></v-img>
                                            <div class="text-container d-flex align-start justify-space-between">
                                            <p class="fs-21 text-quaternary marcellus-sc">
                                                Active Protection
                                            </p>
                                            <button class="iconButton button-root button_variant-tertiary button_size-default rounded-circle button_variant_icon" type="button">
                                                <svg class="" width="16" height="16" viewBox="0 0 16 16" fill="#9c9da1" role="img" focusable="false" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" style="--icon-color: #9c9da1;"><path d="M8.75 4C8.75 3.58579 8.41421 3.25 8 3.25C7.58579 3.25 7.25 3.58579 7.25 4V7.25H4C3.58579 7.25 3.25 7.58579 3.25 8C3.25 8.41421 3.58579 8.75 4 8.75H7.25V12C7.25 12.4142 7.58579 12.75 8 12.75C8.41421 12.75 8.75 12.4142 8.75 12V8.75H12C12.4142 8.75 12.75 8.41421 12.75 8C12.75 7.58579 12.4142 7.25 12 7.25H8.75V4Z"></path></svg>
                                            </button>
                                            </div>
                                        </v-card>
                                        <v-dialog scrollable style="pointer-events: auto;opacity: 1;will-change: transform;" activator="parent" max-width="960">
                                            <template v-slot:default="{ isActive }">
                                                <v-card rounded="0" elevation="0" class="bg-very-dark">
                                                    <v-card-title class="d-flex r-justify-end align-center r-p-x-4">
                                                        <button class="iconButton button-root button_variant-tertiary differentCard_close button_size-default rounded-circle button_variant_icon" type="button" @click="isActive.value = false">
                                                            <i class="mdi mdi-close"></i>
                                                        </button>
                                                    </v-card-title>
                                                    <v-card-text style="max-height: 1000px;">
                                                        <div style="min-width: 100%; display: table;">
                                                            <div class="hide-tablet Spacer_root__uoSvA" style="--height: -128px;"></div>
                                                            <div class="show-tablet Spacer_root__uoSvA" style="--height: -48px;"></div>
                                                            <div>
                                                                <img alt="" data-nosnippet="true" data-loaded="true" loading="lazy" width="960" height="914" decoding="async" data-nimg="1" class="Image_root__UkRqc" v-show="this.darkTheme == 1" src="https://imagedelivery.net/P4z5C24T1A4q673nx_eYTw/465e48b4-06d5-4b47-f5d4-fe493041d500/public" style="color: transparent;">
                                                                <img alt="" data-nosnippet="true" data-loaded="true" loading="lazy" width="960" height="914" decoding="async" data-nimg="1" class="Image_root__UkRqc" v-show="this.darkTheme == 0" src="https://imagedelivery.net/P4z5C24T1A4q673nx_eYTw/978fb5cb-7333-4313-efee-0a0dca835a00/public" style="color: transparent;">
                                                            </div>
                                                            <div class="hide-tablet Spacer_root__uoSvA" style="--height: -248px;"></div>
                                                            <div class="show-tablet Spacer_root__uoSvA" style="--height: -64px;"></div>
                                                            <div style="max-width: 720px; margin: 0px auto;">
                                                                <div style="transform: none; transform-origin: 50% 50% 0px;">
                                                                    <div class="Spacer_root__uoSvA" style="--height: 40px;"></div>
                                                                    <div class="flex_root flex-column prose text-tertiary">
                                                                        <div class="flex_root flex-column prose text-tertiary">
                                                                            <p>
                                                                                Our multi-layered defense system actively guards against market manipulation and unfair practices.
                                                                            </p>
                                                                            <ol class="pl-8">
                                                                                <li>Ownership Squatting Prevention: Identify and ban wallets that hold tokens solely to accumulate Future Rewards without contributing to the community.</li>
                                                                                <li>Wash Trading Detection: Advanced algorithms flag suspicious patterns, protecting the integrity of price discovery.</li>
                                                                                <li>Community Reporting: Empower community members to report suspicious activity, creating a self-policing ecosystem.</li>
                                                                                <li>Fair Distribution: Ensure Future Rewards flow to genuine community members who contribute to the token's growth.</li>
                                                                            </ol>
                                                                        </div>
                                                                    </div>
                                                                    <div class="WorkflowCard_bottomSpacer__ESEnP"></div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </v-card-text>
                                                </v-card>
                                            </template>
                                        </v-dialog>
                                    </v-hover>
                                </div>
                            </div>
                            <div class="Carousel_item__k73Fv">
                                <div style="opacity: 1;">
                                    <v-hover v-slot="{ isHovering, props }">
                                        <v-card class="differentCard reset_reset-button" color="black" :class="{ 'on-hover': isHovering }" :elevation="isHovering ? 12 : 0" v-bind="props" rounded="0" >
                                            <v-img  src="https://imagedelivery.net/P4z5C24T1A4q673nx_eYTw/7ab6375a-25eb-4b60-4825-52fbbf4c7500/public"  width="960" height="914" cover class="Image_root__UkRqc trans-image-2" v-show="this.darkTheme == 1" style="color: transparent; position: relative;"> </v-img>
                                            <v-img  src="https://imagedelivery.net/P4z5C24T1A4q673nx_eYTw/d15f620d-0f19-4090-2f68-716046c22900/public"  width="960" height="914" cover class="Image_root__UkRqc trans-image-2" v-show="this.darkTheme == 0" style="color: transparent; position: relative;"> </v-img>
                                            <div class="text-container d-flex align-start justify-space-between">
                                            <p class="fs-21 text-quaternary marcellus-sc">
                                                Trust Through Truth
                                            </p>
                                            <button class="iconButton button-root button_variant-tertiary button_size-default rounded-circle button_variant_icon" type="button">
                                                <svg class="" width="16" height="16" viewBox="0 0 16 16" fill="#9c9da1" role="img" focusable="false" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" style="--icon-color: #9c9da1;"><path d="M8.75 4C8.75 3.58579 8.41421 3.25 8 3.25C7.58579 3.25 7.25 3.58579 7.25 4V7.25H4C3.58579 7.25 3.25 7.58579 3.25 8C3.25 8.41421 3.58579 8.75 4 8.75H7.25V12C7.25 12.4142 7.58579 12.75 8 12.75C8.41421 12.75 8.75 12.4142 8.75 12V8.75H12C12.4142 8.75 12.75 8.41421 12.75 8C12.75 7.58579 12.4142 7.25 12 7.25H8.75V4Z"></path></svg>
                                            </button>
                                            </div>
                                        </v-card>
                                        <v-dialog scrollable style="pointer-events: auto;opacity: 1;will-change: transform;" activator="parent" max-width="960">
                                            <template v-slot:default="{ isActive }">
                                                <v-card rounded="0" elevation="0" class="bg-very-dark">
                                                    <v-card-title class="d-flex r-justify-end align-center r-p-x-4">
                                                        <button class="iconButton button-root button_variant-tertiary differentCard_close button_size-default rounded-circle button_variant_icon" type="button" @click="isActive.value = false">
                                                            <i class="mdi mdi-close"></i>
                                                        </button>
                                                    </v-card-title>
                                                    <v-card-text style="max-height: 1000px;">
                                                        <div style="min-width: 100%; display: table;">
                                                            <div class="hide-tablet Spacer_root__uoSvA" style="--height: -128px;"></div>
                                                            <div class="show-tablet Spacer_root__uoSvA" style="--height: -48px;"></div>
                                                            <div>
                                                                <img alt="" data-nosnippet="true" data-loaded="true" loading="lazy" width="960" height="914" decoding="async" data-nimg="1" class="Image_root__UkRqc" v-show="this.darkTheme == 1" src="https://imagedelivery.net/P4z5C24T1A4q673nx_eYTw/7ab6375a-25eb-4b60-4825-52fbbf4c7500/public" style="color: transparent;">
                                                                <img alt="" data-nosnippet="true" data-loaded="true" loading="lazy" width="960" height="914" decoding="async" data-nimg="1" class="Image_root__UkRqc" v-show="this.darkTheme == 0" src="https://imagedelivery.net/P4z5C24T1A4q673nx_eYTw/d15f620d-0f19-4090-2f68-716046c22900/public" style="color: transparent;">
                                                            </div>
                                                            <div class="hide-tablet Spacer_root__uoSvA" style="--height: -248px;"></div>
                                                            <div class="show-tablet Spacer_root__uoSvA" style="--height: -64px;"></div>
                                                            <div style="max-width: 720px; margin: 0px auto;">
                                                                <div style="transform: none; transform-origin: 50% 50% 0px;">
                                                                    <div class="Spacer_root__uoSvA" style="--height: 40px;"></div>
                                                                    <div class="flex_root flex-column prose text-tertiary">
                                                                        <div class="flex_root flex-column prose text-tertiary">
                                                                            <p>
                                                                                Transparency builds the foundation for strong, sustainable communities and fair markets.
                                                                            </p>
                                                                            <ol class="pl-8">
                                                                                <li>Real Identities: Each wallet's trading history is public, fostering accountability and genuine community engagement.</li>
                                                                                <li>Transparent Rewards: Track how Future Rewards are earned, calculated, and distributed in real-time.</li>
                                                                                <li>Community Reputation: Build and maintain your standing through verified positive trading behavior and community contribution.</li>
                                                                                <li>Market Integrity: Enable true price discovery through genuine transactions, not market manipulation.</li>
                                                                            </ol>
                                                                        </div>
                                                                    </div>
                                                                    <div class="WorkflowCard_bottomSpacer__ESEnP"></div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </v-card-text>
                                                </v-card>
                                            </template>
                                        </v-dialog>
                                    </v-hover>
                                </div>
                            </div>
                            <div class="Carousel_item__k73Fv">
                                <div style="opacity: 1;">
                                    <v-hover v-slot="{ isHovering, props }">
                                        <v-card class="differentCard reset_reset-button" color="black" :class="{ 'on-hover': isHovering }" :elevation="isHovering ? 12 : 0" v-bind="props" rounded="0" >
                                            <v-img  src="https://imagedelivery.net/P4z5C24T1A4q673nx_eYTw/1b289682-26c4-439e-a228-315a959a0b00/public"  width="960" height="914" cover class="Image_root__UkRqc trans-image-2" v-show="this.darkTheme == 1" style="color: transparent; position: relative;"></v-img>
                                            <v-img  src="https://imagedelivery.net/P4z5C24T1A4q673nx_eYTw/c078b8f6-1242-4d3e-431e-f0f103467500/public"  width="960" height="914" cover class="Image_root__UkRqc trans-image-2" v-show="this.darkTheme == 0" style="color: transparent; position: relative;"></v-img>
                                            <div class="text-container d-flex align-start justify-space-between">
                                            <p class="fs-21 text-quaternary marcellus-sc">
                                                Real-Time Blacklist
                                            </p>
                                            <button class="iconButton button-root button_variant-tertiary button_size-default rounded-circle button_variant_icon" type="button">
                                                <svg class="" width="16" height="16" viewBox="0 0 16 16" fill="#9c9da1" role="img" focusable="false" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" style="--icon-color: #9c9da1;"><path d="M8.75 4C8.75 3.58579 8.41421 3.25 8 3.25C7.58579 3.25 7.25 3.58579 7.25 4V7.25H4C3.58579 7.25 3.25 7.58579 3.25 8C3.25 8.41421 3.58579 8.75 4 8.75H7.25V12C7.25 12.4142 7.58579 12.75 8 12.75C8.41421 12.75 8.75 12.4142 8.75 12V8.75H12C12.4142 8.75 12.75 8.41421 12.75 8C12.75 7.58579 12.4142 7.25 12 7.25H8.75V4Z"></path></svg>
                                            </button>
                                            </div>
                                        </v-card>
                                        <v-dialog scrollable style="pointer-events: auto;opacity: 1;will-change: transform;" activator="parent" max-width="960">
                                            <template v-slot:default="{ isActive }">
                                                <v-card rounded="0" elevation="0" class="bg-very-dark">
                                                    <v-card-title class="d-flex r-justify-end align-center r-p-x-4">
                                                        <button class="iconButton button-root button_variant-tertiary differentCard_close button_size-default rounded-circle button_variant_icon" type="button" @click="isActive.value = false">
                                                            <i class="mdi mdi-close"></i>
                                                        </button>
                                                    </v-card-title>
                                                    <v-card-text style="max-height: 1000px;">
                                                        <div style="min-width: 100%; display: table;">
                                                            <div class="hide-tablet Spacer_root__uoSvA" style="--height: -128px;"></div>
                                                            <div class="show-tablet Spacer_root__uoSvA" style="--height: -48px;"></div>
                                                            <div>
                                                                <img alt="" data-nosnippet="true" data-loaded="true" loading="lazy" width="960" height="914" decoding="async" data-nimg="1" class="Image_root__UkRqc" v-show="this.darkTheme == 1" src="https://imagedelivery.net/P4z5C24T1A4q673nx_eYTw/1b289682-26c4-439e-a228-315a959a0b00/public" style="color: transparent;">
                                                                <img alt="" data-nosnippet="true" data-loaded="true" loading="lazy" width="960" height="914" decoding="async" data-nimg="1" class="Image_root__UkRqc" v-show="this.darkTheme == 0" src="https://imagedelivery.net/P4z5C24T1A4q673nx_eYTw/c078b8f6-1242-4d3e-431e-f0f103467500/public" style="color: transparent;">
                                                            </div>
                                                            <div class="hide-tablet Spacer_root__uoSvA" style="--height: -248px;"></div>
                                                            <div class="show-tablet Spacer_root__uoSvA" style="--height: -64px;"></div>
                                                            <div style="max-width: 720px; margin: 0px auto;">
                                                                <div style="transform: none; transform-origin: 50% 50% 0px;">
                                                                    <div class="Spacer_root__uoSvA" style="--height: 40px;"></div>
                                                                    <div class="flex_root flex-column prose text-tertiary">
                                                                        <div class="flex_root flex-column prose text-tertiary">
                                                                            <p>
                                                                                The public "Black List" exposes wallets banned for malicious behavior. This transparent approach:
                                                                            </p>
                                                                            <ol class="pl-8">
                                                                                <li>Deters bad actors</li>
                                                                                <li>Protects genuine traders</li>
                                                                                <li>Ensures fair reward distribution</li>
                                                                                <li>Maintains market integrity</li>
                                                                            </ol>
                                                                            <p>
                                                                                At Untrading, we believe sunlight is the best disinfectant. Complete transparency creates trust, and trust builds lasting communities
                                                                            </p>
                                                                        </div>
                                                                    </div>
                                                                    <div class="WorkflowCard_bottomSpacer__ESEnP"></div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </v-card-text>
                                                </v-card>
                                            </template>
                                        </v-dialog>
                                    </v-hover>
                                </div>
                            </div>
                            <div class="Carousel_item__k73Fv">
                                <div style="opacity: 1;">
                                    <v-hover v-slot="{ isHovering, props }">
                                        <v-card class="differentCard reset_reset-button" color="black" :class="{ 'on-hover': isHovering }" :elevation="isHovering ? 12 : 0" v-bind="props" rounded="0" >
                                            <v-img  src="https://imagedelivery.net/P4z5C24T1A4q673nx_eYTw/fa347ffc-f466-434b-f927-e145a6186700/public"  width="960" height="914" cover class="Image_root__UkRqc trans-image-2" v-show="this.darkTheme == 1" style="color: transparent; position: relative;"></v-img>
                                            <v-img  src="https://imagedelivery.net/P4z5C24T1A4q673nx_eYTw/bb0c019d-2554-4513-5e57-2810375b0e00/public"  width="960" height="914" cover class="Image_root__UkRqc trans-image-2" v-show="this.darkTheme == 0" style="color: transparent; position: relative;"></v-img>
                                            <div class="text-container d-flex align-start justify-space-between">
                                            <p class="fs-21 text-quaternary marcellus-sc">
                                                On-Chain Verification
                                            </p>
                                            <button class="iconButton button-root button_variant-tertiary button_size-default rounded-circle button_variant_icon" type="button">
                                                <svg class="" width="16" height="16" viewBox="0 0 16 16" fill="#9c9da1" role="img" focusable="false" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" style="--icon-color: #9c9da1;"><path d="M8.75 4C8.75 3.58579 8.41421 3.25 8 3.25C7.58579 3.25 7.25 3.58579 7.25 4V7.25H4C3.58579 7.25 3.25 7.58579 3.25 8C3.25 8.41421 3.58579 8.75 4 8.75H7.25V12C7.25 12.4142 7.58579 12.75 8 12.75C8.41421 12.75 8.75 12.4142 8.75 12V8.75H12C12.4142 8.75 12.75 8.41421 12.75 8C12.75 7.58579 12.4142 7.25 12 7.25H8.75V4Z"></path></svg>
                                            </button>
                                            </div>
                                        </v-card>
                                        <v-dialog scrollable style="pointer-events: auto;opacity: 1;will-change: transform;" activator="parent" max-width="960">
                                            <template v-slot:default="{ isActive }">
                                                <v-card rounded="0" elevation="0" class="bg-very-dark">
                                                    <v-card-title class="d-flex r-justify-end align-center r-p-x-4">
                                                        <button class="iconButton button-root button_variant-tertiary differentCard_close button_size-default rounded-circle button_variant_icon" type="button" @click="isActive.value = false">
                                                            <i class="mdi mdi-close"></i>
                                                        </button>
                                                    </v-card-title>
                                                    <v-card-text style="max-height: 1000px;">
                                                        <div style="min-width: 100%; display: table;">
                                                            <div class="hide-tablet Spacer_root__uoSvA" style="--height: -128px;"></div>
                                                            <div class="show-tablet Spacer_root__uoSvA" style="--height: -48px;"></div>
                                                            <div>
                                                                <img alt="" data-nosnippet="true" data-loaded="true" loading="lazy" width="960" height="914" decoding="async" data-nimg="1" class="Image_root__UkRqc" v-show="this.darkTheme == 1" src="https://imagedelivery.net/P4z5C24T1A4q673nx_eYTw/fa347ffc-f466-434b-f927-e145a6186700/public" style="color: transparent;">
                                                                <img alt="" data-nosnippet="true" data-loaded="true" loading="lazy" width="960" height="914" decoding="async" data-nimg="1" class="Image_root__UkRqc" v-show="this.darkTheme == 0" src="https://imagedelivery.net/P4z5C24T1A4q673nx_eYTw/bb0c019d-2554-4513-5e57-2810375b0e00/public" style="color: transparent;">
                                                            </div>
                                                            <div class="hide-tablet Spacer_root__uoSvA" style="--height: -248px;"></div>
                                                            <div class="show-tablet Spacer_root__uoSvA" style="--height: -64px;"></div>
                                                            <div style="max-width: 720px; margin: 0px auto;">
                                                                <div style="transform: none; transform-origin: 50% 50% 0px;">
                                                                    <div class="Spacer_root__uoSvA" style="--height: 40px;"></div>
                                                                    <div class="flex_root flex-column prose text-tertiary">
                                                                        <div class="flex_root flex-column prose text-tertiary">
                                                                            <p>
                                                                                Every transaction detail is recorded on-chain:
                                                                            </p>
                                                                            <ol class="pl-8">
                                                                                <li>Buyer and seller identities</li>
                                                                                <li>Price and volume</li>
                                                                                <li>Future Rewards calculations</li>
                                                                                <li>Treasury contributions</li>
                                                                            </ol>
                                                                            <p>All verifiable, all the time.</p>
                                                                        </div>
                                                                    </div>
                                                                    <div class="WorkflowCard_bottomSpacer__ESEnP"></div>
                                                                </div>
                                                            </div>
                                                         </div>
                                                    </v-card-text>
                                                </v-card>
                                            </template>
                                        </v-dialog>
                                    </v-hover>
                                </div>
                            </div>
                            <div class="Carousel_item__k73Fv">
                                <div style="opacity: 1;">
                                    <v-hover v-slot="{ isHovering, props }">
                                        <v-card class="differentCard reset_reset-button" color="black" :class="{ 'on-hover': isHovering }" :elevation="isHovering ? 12 : 0" v-bind="props" rounded="0" >
                                            <v-img  src="https://imagedelivery.net/P4z5C24T1A4q673nx_eYTw/7a2ba847-c8f2-4709-2edc-e3043cfffe00/public"  width="960" height="914" cover class="Image_root__UkRqc rotate" v-show="this.darkTheme == 1" style="color: transparent; position: relative;"></v-img>
                                            <v-img  src="https://imagedelivery.net/P4z5C24T1A4q673nx_eYTw/aa2ca273-e0f8-4c2b-3e73-ce004d3c7400/public"  width="960" height="914" cover class="Image_root__UkRqc trans-image-2 rotate" v-show="this.darkTheme == 0" style="color: transparent; position: relative;"></v-img>
                                            <div class="text-container d-flex align-start justify-space-between">
                                            <p class="fs-21 text-quaternary marcellus-sc">
                                                Community Governance
                                            </p>
                                            <button class="iconButton button-root button_variant-tertiary button_size-default rounded-circle button_variant_icon" type="button">
                                                <svg class="" width="16" height="16" viewBox="0 0 16 16" fill="#9c9da1" role="img" focusable="false" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" style="--icon-color: #9c9da1;"><path d="M8.75 4C8.75 3.58579 8.41421 3.25 8 3.25C7.58579 3.25 7.25 3.58579 7.25 4V7.25H4C3.58579 7.25 3.25 7.58579 3.25 8C3.25 8.41421 3.58579 8.75 4 8.75H7.25V12C7.25 12.4142 7.58579 12.75 8 12.75C8.41421 12.75 8.75 12.4142 8.75 12V8.75H12C12.4142 8.75 12.75 8.41421 12.75 8C12.75 7.58579 12.4142 7.25 12 7.25H8.75V4Z"></path></svg>
                                            </button>
                                            </div>
                                        </v-card>
                                        <v-dialog scrollable style="pointer-events: auto;opacity: 1;will-change: transform;" activator="parent" max-width="960">
                                            <template v-slot:default="{ isActive }">
                                                <v-card rounded="0" elevation="0" class="bg-very-dark">
                                                    <v-card-title class="d-flex r-justify-end align-center r-p-x-4">
                                                        <button class="iconButton button-root button_variant-tertiary differentCard_close button_size-default rounded-circle button_variant_icon" type="button" @click="isActive.value = false">
                                                            <i class="mdi mdi-close"></i>
                                                        </button>
                                                    </v-card-title>
                                                    <v-card-text style="max-height: 1000px;">
                                                        <div style="min-width: 100%; display: table;">
                                                            <div class="hide-tablet Spacer_root__uoSvA" style="--height: -128px;"></div>
                                                            <div class="show-tablet Spacer_root__uoSvA" style="--height: -48px;"></div>
                                                            <div>
                                                                <img alt="" data-nosnippet="true" data-loaded="true" loading="lazy" width="960" height="914" decoding="async" data-nimg="1" class="Image_root__UkRqc rotate" v-show="this.darkTheme == 1" src="https://imagedelivery.net/P4z5C24T1A4q673nx_eYTw/7a2ba847-c8f2-4709-2edc-e3043cfffe00/public" style="color: transparent;">
                                                                <img alt="" data-nosnippet="true" data-loaded="true" loading="lazy" width="960" height="914" decoding="async" data-nimg="1" class="Image_root__UkRqc rotate" v-show="this.darkTheme == 0" src="https://imagedelivery.net/P4z5C24T1A4q673nx_eYTw/aa2ca273-e0f8-4c2b-3e73-ce004d3c7400/public" style="color: transparent;">
                                                            </div>
                                                            <div class="hide-tablet Spacer_root__uoSvA" style="--height: -248px;"></div>
                                                            <div class="show-tablet Spacer_root__uoSvA" style="--height: -64px;"></div>
                                                            <div style="max-width: 720px; margin: 0px auto;">
                                                                <div style="transform: none; transform-origin: 50% 50% 0px;">
                                                                    <div class="Spacer_root__uoSvA" style="--height: 40px;"></div>
                                                                    <div class="flex_root flex-column prose text-tertiary">
                                                                        <div class="flex_root flex-column prose text-tertiary">
                                                                            <p>
                                                                                Token holders actively participate in:
                                                                            </p>
                                                                            <ol class="pl-8">
                                                                                <li>Reporting suspicious activity</li>
                                                                                <li>Voting on blacklist additions/removals</li>
                                                                                <li>Setting community standards</li>
                                                                                <li>Determining treasury use</li>
                                                                            </ol>
                                                                        </div>
                                                                    </div>
                                                                    <div class="WorkflowCard_bottomSpacer__ESEnP"></div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </v-card-text>
                                                </v-card>
                                            </template>
                                        </v-dialog>
                                    </v-hover>
                                </div>
                            </div>
                            <div class="Carousel_item__k73Fv">
                                <div style="opacity: 1;">
                                    <v-hover v-slot="{ isHovering, props }">
                                        <v-card class="differentCard reset_reset-button" color="black" :class="{ 'on-hover': isHovering }" :elevation="isHovering ? 12 : 0" v-bind="props" rounded="0" >
                                            <v-img  src="https://imagedelivery.net/P4z5C24T1A4q673nx_eYTw/13d5c2e7-7283-4669-b300-84323ca7f300/public"  width="960" height="914" cover class="Image_root__UkRqc trans-image-2" v-show="this.darkTheme == 1" style="color: transparent;"></v-img>
                                            <v-img  src="https://imagedelivery.net/P4z5C24T1A4q673nx_eYTw/ff65db1c-1e19-4e71-d99e-1be18a56b700/public"  width="960" height="914" cover class="Image_root__UkRqc trans-image-2" v-show="this.darkTheme == 0" style="color: transparent;"></v-img>
                                            <div class="text-container d-flex align-start justify-space-between">
                                            <p class="fs-21 text-quaternary marcellus-sc">
                                                Reward Distribution Tracking
                                            </p>
                                            <button class="iconButton button-root button_variant-tertiary button_size-default rounded-circle button_variant_icon" type="button">
                                                <svg class="" width="16" height="16" viewBox="0 0 16 16" fill="#9c9da1" role="img" focusable="false" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" style="--icon-color: #9c9da1;"><path d="M8.75 4C8.75 3.58579 8.41421 3.25 8 3.25C7.58579 3.25 7.25 3.58579 7.25 4V7.25H4C3.58579 7.25 3.25 7.58579 3.25 8C3.25 8.41421 3.58579 8.75 4 8.75H7.25V12C7.25 12.4142 7.58579 12.75 8 12.75C8.41421 12.75 8.75 12.4142 8.75 12V8.75H12C12.4142 8.75 12.75 8.41421 12.75 8C12.75 7.58579 12.4142 7.25 12 7.25H8.75V4Z"></path></svg>
                                            </button>
                                            </div>
                                        </v-card>
                                        <v-dialog scrollable style="pointer-events: auto;opacity: 1;will-change: transform;" activator="parent" max-width="960">
                                            <template v-slot:default="{ isActive }">
                                                <v-card rounded="0" elevation="0" class="bg-very-dark">
                                                    <v-card-title class="d-flex r-justify-end align-center r-p-x-4">
                                                        <button class="iconButton button-root button_variant-tertiary differentCard_close button_size-default rounded-circle button_variant_icon" type="button" @click="isActive.value = false">
                                                            <i class="mdi mdi-close"></i>
                                                        </button>
                                                    </v-card-title>
                                                    <v-card-text style="max-height: 1000px;">
                                                        <div style="min-width: 100%; display: table;">
                                                            <div class="hide-tablet Spacer_root__uoSvA" style="--height: -128px;"></div>
                                                            <div class="show-tablet Spacer_root__uoSvA" style="--height: -48px;"></div>
                                                            <div>
                                                                <img alt="" data-nosnippet="true" data-loaded="true" loading="lazy" width="960" height="914" decoding="async" data-nimg="1" class="Image_root__UkRqc" v-show="this.darkTheme == 1" src="https://imagedelivery.net/P4z5C24T1A4q673nx_eYTw/23ea3962-cea4-4271-b07e-166239314900/public" style="color: transparent;">
                                                                <img alt="" data-nosnippet="true" data-loaded="true" loading="lazy" width="960" height="914" decoding="async" data-nimg="1" class="Image_root__UkRqc" v-show="this.darkTheme == 0" src="https://imagedelivery.net/P4z5C24T1A4q673nx_eYTw/ff65db1c-1e19-4e71-d99e-1be18a56b700/public" style="color: transparent;">
                                                            </div>
                                                            <div class="hide-tablet Spacer_root__uoSvA" style="--height: -248px;"></div>
                                                            <div class="show-tablet Spacer_root__uoSvA" style="--height: -64px;"></div>
                                                            <div style="max-width: 720px; margin: 0px auto;">
                                                                <div style="transform: none; transform-origin: 50% 50% 0px;">
                                                                    <div class="Spacer_root__uoSvA" style="--height: 40px;"></div>
                                                                    <div class="flex_root flex-column prose text-tertiary">
                                                                        <div class="flex_root flex-column prose text-tertiary">
                                                                            <p>
                                                                                Monitor how Future Rewards flow through your community:
                                                                            </p>
                                                                            <ol class="pl-8">
                                                                                <li>Track earnings in real-time</li>
                                                                                <li>Verify distribution fairness</li>
                                                                                <li>See community contribution levels</li>
                                                                                <li>Understand value creation patterns</li>
                                                                            </ol>
                                                                        </div>
                                                                    </div>
                                                                    <div class="WorkflowCard_bottomSpacer__ESEnP"></div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </v-card-text>
                                                </v-card>
                                            </template>
                                        </v-dialog>
                                    </v-hover>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="mt-8 text-center">
                <v-btn icon :disabled="scrollToLeftDisabled" variant="flat" size="small" @click="scrollToLeft()">
                    <v-icon class="text-tertiary">mdi mdi-chevron-left</v-icon>
                </v-btn>
                <span class="px-1"></span>
                <v-btn icon :disabled="scrollToRightDisabled" variant="flat" size="small" @click="scrollToRight()">
                    <v-icon class="text-tertiary">mdi mdi-chevron-right</v-icon>
                </v-btn>
            </div>
            <div class="mt-8">
                <a href="https://5173.foundation/" target="_blank" type="button" class="button_root button_variant-secondary button_size-x-default button_variant link_root" rel="noopener">
                    <span class="text-tertiary" style="padding: 0px;">Explore Full Transparency</span><v-icon class="text-black01">mdi mdi-chevron-right</v-icon>
                </a>
            </div>
        </div>
	</div>
</template>
<script>
import { mapGetters } from "vuex";
export default {
    data(){
        return {
            // 向左滚动按钮禁用状态
            scrollToLeftDisabled: true,
            // 向右滚动按钮禁用状态
            scrollToRightDisabled: false,
        }
    },
    components: {  },
    created(){

    },
    mounted(){

    },
    computed: {
        ...mapGetters(["darkTheme"])
    },
    watch:{

    },
    methods: {
        // 更新按钮状态
        updateButtonState() {
            const container = this.$refs.Carousel_scroll_container;
            const content = this.$refs.Carousel_scroll_content;
            this.scrollToLeftDisabled = container.scrollLeft === 0;
            this.scrollToRightDisabled = container.scrollLeft + container.clientWidth >= content.scrollWidth;
        },
        // 向左滚动
        scrollToLeft() {
            const container = this.$refs.Carousel_scroll_container;
            container.scrollBy({ left: -800, behavior: 'smooth' });
            setTimeout(() => { this.updateButtonState() }, 1000);
        },
        // 向右滚动
        scrollToRight() {
            const container = this.$refs.Carousel_scroll_container;
            container.scrollBy({ left: 800, behavior: 'smooth' });
            setTimeout(() => { this.updateButtonState() }, 1000);
        },
    }
}
</script>
<style scoped>
.text-container {
    position: absolute;
    bottom: 0;
    /* 对齐到图片底部 */
    left: 0;
    width: 100%;
    padding: 16px;
    /* 可根据需要调整内边距 */
    box-sizing: border-box;
}

[data-radix-scroll-area-viewport] {
    scrollbar-width: none; /* Firefox隐藏滚动条 */
    -ms-overflow-style: none; /* IE和Edge隐藏滚动条 */
    -webkit-overflow-scrolling: touch; /* 启用触控设备的平滑滚动 */
}

[data-radix-scroll-area-viewport]::-webkit-scrollbar {
    display: none; /* Chrome、Safari等WebKit浏览器隐藏滚动条 */
}

:deep(.v-echarts-dialog){
    width: auto;
}
</style>