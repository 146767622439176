<template>
	<div class="layoutContentVideo justify-center r-p-x-0">
        <div class="extra-glow-video-right"></div>
        <div class="Grid_root__iRfoa grid-style-revolution">
            <div class="Grid_a__vY7M8">
                <div class="video-container">
                    <iframe width="560" height="315" src="https://www.youtube.com/embed/hDjKo8WtKr0?si=e-_zUEHQS_hVZilo" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                </div>
            </div>
            <div class="Grid_b___vQi_ r-m-t-8 r-p-x-6">
                <h2 class="typography_h1 lexend-deca text-quaternary">What is Untrading?</h2>
                <div class="Spacer_root__uoSvA" style="--height:24px"></div>
                <p class="lexend-deca fs-17 text-tertiary">
                    Listen to this introductory podcast episode to understand Untrading's technology and purpose.
                </p>
            </div>
        </div>
	</div>
</template>
<script>
export default {
    data(){
        return {

        }
    },
    components: {  },
    created(){

    },
    mounted(){

    },
    computed: {

    },
    watch:{

    },
    methods: {

    }
}
</script>
<style scoped>
.video-container {
	position: relative;
	padding-bottom: 56.25%;
	/* 3:2 Aspect Ratio */
	height: 0;
	overflow: hidden;
	max-width: 100%;
}

.video-container iframe {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}
</style>